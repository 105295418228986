import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import FileUploadDialogComponent from '../../quotation-service-ui/src/components/file.upload.dialog';
import MemberTemplateModal from '../../quotation-service-ui/src/components/member.template.dialog';
import { ClientService } from '../../remote-api/api/client-services';
import { EndorsementService } from '../../remote-api/api/endorsement-services';
import { MemberService } from '../../remote-api/api/member-services';
import { PolicyService } from '../../remote-api/api/policy-services';
import { FettleMultiFieldSearch } from '../../shared-components';
import { saveAs } from 'file-saver';
import { InvoiceService, ProductService } from '../../remote-api/fettle-remote-api';
import { QuotationService } from '../../remote-api/api/quotation-services';

const clientService = new ClientService();

const memberservice = new MemberService();
const proposerservice = new PolicyService();
const endorsementservice = new EndorsementService();
const invoiceservice = new InvoiceService();
const productService = new ProductService();
const quotationService = new QuotationService();

const dataSource$ = (fields, pageRequest = { page: 0, size: 10 }) => {
  let pagerequestquery = {
    page: pageRequest.page,
    size: pageRequest.size,
    summary: true,
    active: true,
    sort: ['rowLastUpdatedDate dsc'],
  };
  let pagerequestquery3 = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
    sort: ['rowLastUpdatedDate dsc'],
  };
  Object.keys(fields)
    .filter(key => !!fields[key])
    .forEach(key => {
      if (key === 'policyNo') {
        pagerequestquery['policyNo'] = fields[key];
      }
    });
  Object.keys(fields)
    .filter(key => !!fields[key])
    .forEach(key => {
      if (key === 'name') {
        pagerequestquery3['name'] = fields[key];
      }
    });
  return clientService
    .getClients(pagerequestquery3)
    .pipe(
      map(cdata => {
        return cdata;
      }),
    )
    .pipe(
      switchMap(cdata => {
        return proposerservice.getPolicy(pagerequestquery).pipe(
          map(data => {
            cdata.content.forEach(cl => {
              data.content.forEach(pl => {
                if (cl.id === pl.clientId) {
                  pl['proposerName'] = cl.clientBasicDetails.displayName;
                }
              });
            });
            let content = data.content;
            let arr = [];
            if (pagerequestquery['policyNo']) {
              data.content.forEach(po => {
                // po.policyDetails.forEach(pd => {
                if (po.policyNumber === pagerequestquery['policyNo']) {
                  let obj = {
                    policyNo: po.policyNumber,
                    anniversary: po.anniversary,
                    policyDate: new Date(po.policyStartDate).toLocaleDateString(),
                    proposerName: po.proposerName,
                    proposerId: po.id,
                    policyId: po.id,
                    invoiceId: po?.refSrcId,
                  };
                  arr.push(obj);
                }
                // })
              });
            }
            if (pagerequestquery3['name']) {
              if (arr.length === 0) {
                cdata.content.forEach(cl => {
                  data.content.forEach(po => {
                    if (cl.id === po.clientId) {
                      // po.policyDetails.forEach(pd => {
                      let obj = {
                        policyNo: po.policyNumber,
                        anniversary: po.anniversary,
                        policyDate: new Date(po.policyStartDate).toLocaleDateString(),
                        proposerName: po.proposerName,
                        proposerId: po.id,
                        policyId: po.id,
                        invoiceId: po?.refSrcId,
                      };
                      arr.push(obj);
                      // })
                    }
                  });
                });
              }
            }
            data.content = arr;
            // }
            // let content = data.content;
            // let records = content.map(item => {

            //   return item;
            // });
            // data.content = records;
            return data;
          }),
        );
      }),
    );
};

const fields = [
  { label: 'Policy Code', propertyName: 'policyNo' },
  { label: 'Proposer/Client Name', propertyName: 'name' },
];

const columnsDefinations = [
  { field: 'policyNo', headerName: 'Policy Code' },
  { field: 'anniversary', headerName: 'Anniversary' },
  { field: 'proposerName', headerName: 'Proposer Name' },
  { field: 'policyDate', headerName: 'Policy Date' },
  { field: 'policyId', headerName: 'Policy ID' },
];

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const useStyles = makeStyles(theme => ({
  formBg: {
    padding: '20px 0',
    backgroundColor: '#fff',
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
    '& .MuiFormLabel-asterisk.MuiInputLabel-asterisk': {
      color: 'red',
    },
  },
  formControl: {
    minWidth: 182,
  },
}));

const memberActionOptions = [
  {
    value: 'ADDITION',
    label: 'Addition of members',
  },
  {
    value: 'DELETE',
    label: 'Deletion of Members',
  },
  {
    value: 'NIL',
    label: 'Nil Endorsement',
  },
  {
    value: 'CHANGE_IN_DOB',
    label: 'Change in DOB',
  },
  {
    value: 'CHANGE_IN_GENDER',
    label: 'Change in Gender',
  },
  {
    value: 'CHANGE_IN_RELATIONSHIP',
    label: 'Change in Relationship',
  },
  {
    value: 'SUSPENTION',
    label: 'Suspension of member',
  },
  {
    value: 'REINSTATEMENT',
    label: 'Reinstatement of deleted member',
  },
  {
    value: 'CATEGORY_CHANGE',
    label: 'Plan category change',
  },
  {
    value: 'SUM_INSURED_CHANGED',
    label: 'Sum Insured change',
  },
  {
    value: 'SUSPENSION_OF_POLICY',
    label: 'Suspension of policy',
  },
  {
    value: 'REINSTATEMENT_OF_POLICY',
    label: 'Reinstatement of policy',
  },
  {
    value: 'SUSPENSION_OF_BENEFIT',
    label: 'Suspension of benefit',
  },
  {
    value: 'REINSTATEMENT_OF_BENEFIT',
    label: 'Reinstatement of benefit',
  },
  {
    value: 'POLICY_EXTENSION',
    label: 'Policy extension',
  },
  {
    value: 'CARD_TYPE_CHANGE',
    label: 'Card type change',
  },
  {
    value: 'AML_RISK_CATEGORY',
    label: 'AML risk category',
  },
  {
    value: 'CHANGE_EMPLOYEE_CODE',
    label: 'Change in Employee code',
  },
];

export default function EndorsementDetails(props) {
  const history = useHistory();
  const query = useQuery();
  const classes = useStyles();
  const [clientTypes, setClientTypes] = React.useState([]);
  const [groupTypes, setGroupTypes] = React.useState([]);
  const [prefixes, setPrefixes] = React.useState([]);
  const [suffixes, setSuffixes] = React.useState([]);
  const [showDeleteBtn, setShowDeleteBtn] = React.useState(false);
  const [openRequired, setOpenRequired] = React.useState(false);
  const [firstLoad, setFIrstLoad] = React.useState(true);
  const [apiList, setApiList] = React.useState([]);
  const [calculatePremeiumPayload, setCalculatePremeiumPayload] = useState();
  const [policyDetails, setPolicyDetails] = React.useState({
    proposerName: '',
    policyNo: '',
    anniversary: '',
    policyDate: '',
    policyId: '',
    invoice: '',
  });
  const [invoiceId, setInvoiceId] = useState();

  const { id } = useParams();
  const [selectedEndorsementDate, setSelectedEndorsementDate] = React.useState(new Date());
  const [state, setState] = React.useState({
    endorsementForm: {
      endorsementType: '',
      endorsementAction: '',
      endorsementDate: '',
      code: '',
      proposerId: '',
      policyNo: '',
    },
    // selectedEndorsementDate: new Date(),
    openTemplate: false,
    openMemberUpload: false,
    addFile: false,
    memberUpload: false,
  });

  const handleImport = item => {
    setState({
      ...state,
      endorsementForm: {
        ...state.endorsementForm,
        proposerId: item.proposerId,
        policyNo: item.policyNo,
        invoice: item.invoiceId,
      },
    });
    setInvoiceId(item?.invoiceId);
    setPolicyDetails({
      proposerName: item.proposerName,
      policyNo: item.policyNo,
      policyId: item.policyId,
      anniversary: item.anniversary,
      policyDate: item.policyDate,
      invoice: item.refSrcId,
    });
    // props.handleProspectImport(e);
  };

  React.useEffect(() => {
    if (id) {
      populateData(id);
    }
  }, [id]);

  const populateData = id => {
    endorsementservice.getEndorsementDetail(id).subscribe(res => {
      setState({
        ...state,
        endorsementForm: {
          endorsementType: res.endorsementType,
          endorsementAction: '',
          endorsementDate: res.endorsementDate,
          code: res.code,
          proposerId: res.policyEndorsements[0].proposerId,
          policyNo: res.policyEndorsements[0].policyCode,
        },
        // selectedEndorsementDate: new Date(res.endorsementDate),
        openTemplate: false,
        openMemberUpload: false,
        addFile: false,
        memberUpload: false,
      });
      setSelectedEndorsementDate(new Date(res.endorsementDate));

      proposerservice.getPolicyDetails(res.policyEndorsements[0].proposerId).subscribe(pd => {
        pd.policyDetails.forEach(po => {
          if (po.policyNo === res.policyEndorsements[0].policyCode) {
            clientService.getClientDetails(pd.clientId).subscribe(cl => {
              setPolicyDetails({
                proposerName: cl.clientBasicDetails.displayName,
                policyNo: res.policyEndorsements[0].policyCode,
                anniversary: po.anniversary,
                policyDate: new Date(po.rennewalDate).toLocaleDateString(),
                invoice: po.refSrcId,
              });
              setInvoiceId(po.refSrcId);
            });
          }
        });
      });
    });
  };

  const handleEndorsementDate = date => {
    const timestamp = new Date(date).getTime();

    setState({
      ...state,
      endorsementForm: {
        ...state.endorsementForm,
        endorsementDate: timestamp,
      },
      // selectedEndorsementDate: date
    });
    setSelectedEndorsementDate(date);
  };

  const getMemberConfig = () => {
    return memberservice.getMemberConfiguration().subscribe(res => {
      res.content[0].fields.forEach(el => {
        if (el.sourceApiId) {
          getAPIDetails(el.sourceApiId);
        }
      });
    });
  };

  const getAPIDetails = sourceid => {
    return memberservice.getSourceDetails(sourceid).subscribe(res => {
      setApiList([...apiList, res]);
    });
  };
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  // const useObservable1 = () => {
  useEffect(() => {
    getMemberConfig();
  }, []);
  // };

  // useObservable1();

  const populateDetails = id => {};

  const handleSubmit = event => {
    // let payload = {
    //   endorsementDate: new Date(selectedEndorsementDate).getTime(),
    //   endorsementType: state.endorsementForm.endorsementType,
    //   policyEndorsements: [{ proposerId: state.endorsementForm.proposerId, policyCode: state.endorsementForm.policyNo }],
    // };
    let endId = localStorage.getItem('endorsementId');
    endorsementservice
      .calculatePremium(calculatePremeiumPayload, endId, state.endorsementForm.endorsementAction)
      .subscribe(res => {
        alert('SuccessFull');
      });
    // endorsementservice.saveEndorsements(payload).subscribe(res => {
    //   // handleClose();
    //   setShowDeleteBtn(true);
    // });
  };

  const handleChange = event => {
    const { name, value } = event.target;
    setState({
      endorsementForm: {
        ...state.endorsementForm,
        [name]: value,
      },
    });
  };

  const handleClose = () => {
    history.push('/endorsements?mode=viewList');
    // window.location.reload();
  };

  const handleSnackClose = (event, reason) => {
    setOpenRequired(false);
  };

  const openTemplateModal = () => {
    if (state.endorsementForm.endorsementAction === 'DELETE') {
      const filePath = '/sheets/memberSheet.xlsx';
      saveAs(filePath, 'memberDetails.xlsx');
    } else {
      setState({
        ...state,
        openTemplate: true,
      });
    }
  };

  const closeTemplateModal = () => {
    setState({
      ...state,
      openTemplate: false,
    });
  };

  const openMemberUploadModal = () => {
    setState({
      ...state,
      openMemberUpload: true,
    });
  };

  const closeModal = () => {
    setState({
      ...state,
      openMemberUpload: false,
    });
  };

  const changeFileStat = () => {
    setState({
      ...state,
      addFile: true,
    });
  };

  const getPolicyDetails = () => {
    invoiceservice.getInvoiceDetails(invoiceId).subscribe(res => {
      quotationService.getQuoationDetailsByID(res?.quotationId).subscribe(quo => {
        let payload = {
          id: 'id_703d3add4717',
          prospectId: quo?.prospectId,

          paymentFrequency: quo?.paymentFrequency,
          productId: quo?.productId,
          policyId: policyDetails?.policyId,
          planId: quo?.planId,
          policyStartDate: quo?.policyStartDate,
          policyEndDate: quo?.policyEndDate,
          catagoryPremiumRules: quo?.catagoryPremiumRules,
        };
        setCalculatePremeiumPayload(payload);
      });
    });
  };

  const onComplete = () => {
    setState({
      ...state,
      memberUpload: true,
    });
    getPolicyDetails();
  };

  const handleCancel = () => {};

  const handleDelete = () => {
    let payload = {};
    endorsementservice.deleteMember(payload).subscribe(res => {
      handleClose();
    });
  };

  return (
    <div className={classes.formBg}>
      <div>
        {query.get('mode') === 'edit' ? (
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              marginBottom: '20px',
              height: '2em',
              color: '#000',
              fontSize: '18px',
            }}>
            <span
              style={{
                fontWeight: '600',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                marginLeft: '5px',
              }}>
              Endorsement Management
            </span>
          </Grid>
        ) : null}
      </div>
      {state.openTemplate && (
        <MemberTemplateModal
          closeTemplateModal={closeTemplateModal}
          openTemplate={state.openTemplate}
          apiList={apiList.length !== 0 ? apiList : []}
          action={state.endorsementForm.endorsementAction}
          // handleModalSubmit={handleModalSubmit}
        />
      )}
      <FileUploadDialogComponent
        open={state.openMemberUpload}
        closeModal={closeModal}
        addFile={state.addFile}
        changeFileStat={changeFileStat}
        onComplete={onComplete}
        action={state.endorsementForm.endorsementAction}
        policyCode={policyDetails.policyNo}
        policyId={policyDetails.policyId}
        endorsementDate={selectedEndorsementDate}
        isEndorsement={true}
      />
      <FormControl component="fieldset">
        <FormLabel component="legend">Endorsement Type</FormLabel>
        <RadioGroup
          row
          aria-label="endorsementType"
          name="endorsementType"
          value={state.endorsementForm.endorsementType}
          onChange={handleChange}>
          <FormControlLabel
            value="Individual Endorsement"
            control={<Radio color="primary" />}
            label="Individual Endorsement"
          />
          <FormControlLabel value="Bulk Endorsement" control={<Radio color="primary" />} label="Bulk Endorsement" />
        </RadioGroup>
      </FormControl>
      {state.endorsementForm.endorsementType !== '' && state.endorsementForm.endorsementType !== null && (
        <>
          {!id && (
            <Paper elevation="none">
              <Box p={3} my={2}>
                <FettleMultiFieldSearch
                  $dataSource={dataSource$}
                  fields={fields}
                  onSelect={item => {
                    handleImport(item);
                  }}
                  columnsDefinations={columnsDefinations}
                  dataGridPageSize={10}
                  dataGridScrollHeight="400px"
                />
              </Box>
            </Paper>
          )}
          {state.endorsementForm.endorsementType === 'Bulk Endorsement' && (
            <Grid container spacing={3} style={{ marginTop: '50px' }}>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  name="proposerName"
                  disabled
                  value={policyDetails.proposerName}
                  label="Proposer Name"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField id="standard-basic" name="policyNo" disabled value={policyDetails.policyNo} label="Policy Code" />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  name="anniversary"
                  disabled
                  value={policyDetails.anniversary}
                  label="Anniversary"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  id="standard-basic"
                  name="policyDate"
                  disabled
                  value={policyDetails.policyDate}
                  label="Policy Date"
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                    Member Action
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="endorsementAction"
                    value={state.endorsementForm.endorsementAction}
                    onChange={handleChange}>
                    {memberActionOptions.map(ele => {
                      return (
                        <MenuItem value={ele?.value} key={ele?.value}>
                          {ele?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  {/* <Grid container justify="space-around"> */}
                  <KeyboardDatePicker
                    views={['year', 'month', 'date']}
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    autoOk={true}
                    label="Endorsement Date"
                    value={selectedEndorsementDate}
                    onChange={handleEndorsementDate}
                    KeyboardButtonProps={{
                      'aria-label': 'Choose endorsemment date',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={3}>
                {state.endorsementForm.endorsementAction && (
                  <Button variant="contained" style={{ marginLeft: '10px' }} color="primary" onClick={openTemplateModal}>
                    Download Template
                  </Button>
                )}
              </Grid>
              <Grid item xs={3}>
                {state.endorsementForm.endorsementAction && (
                  <Button variant="contained" size="medium" color="primary" onClick={openMemberUploadModal}>
                    Member Upload
                  </Button>
                )}
              </Grid>
            </Grid>
          )}
        </>
      )}

      {showDeleteBtn ? (
        <Button variant="contained" className="p-button-danger" color="primary" onClick={handleDelete}>
          Delete
        </Button>
      ) : (
        <Grid container spacing={3} style={{ marginTop: '50px', display: 'flex', justifyContent: 'flex-end' }}>
          {!id && (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              {/* Save and exit */}
              Calculate Premuim
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            className={!id ? 'p-button-text' : 'p-button'}
            style={{ marginLeft: '10px' }}
            onClick={handleClose}>
            {!id ? 'Cancel' : 'OK'}
          </Button>
        </Grid>
      )}
    </div>
  );
}
