import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useLocation } from 'react-router-dom';
import LinearProgress from '@material-ui/core/LinearProgress';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';
import { DropzoneArea } from 'material-ui-dropzone';
import PropTypes from 'prop-types';
import React from 'react';
import { QuotationService } from '../../../remote-api/api/quotation-services';
import { EndorsementService } from '../../../remote-api/api/endorsement-services';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';

/* import { FettleDataGrid } from '../../../shared-components'; */

const quotationService = new QuotationService();
const endorsementService = new EndorsementService();

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = theme => ({
  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  categoryButton: {
    marginLeft: '5px',
    marginRight: '5px',
  },
});

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

export default function FileUploadDialogComponent(props) {
  const query1 = useQuery1();

  const [selectedFile, setSelectedFile] = React.useState(null);
  const [tabvalue, setTabValue] = React.useState(0);
  const classes = useStyles();

  const handleTabChange = (e, newValue) => {
    setTabValue(newValue);
  };

  const handleClose = () => {
    props.closeModal();
  };

  const handleChange = files => {
    setSelectedFile(files[0]);
  };

  const doUpload = () => {
    let quotationId = localStorage.getItem('quotationId');

    let pageRequest = {
      isRenewal: query1.get('type') == 'renewal' ? true : false,
    };
    if (props.isEndorsement) {
      if (selectedFile) {
        const formData = new FormData();
        formData.append('filePart', selectedFile);
        // if (props.type === 'ADD') {
        //   endorsementService.uploadTemplateForAddition(formData, props.policyId, props.action).subscribe(res => {
        //     localStorage.setItem('endorsementId', res?.id);
        //     handleClose();
        //     props.onComplete();
        //   });
        // } else {
          endorsementService.uploadTemplate(formData, props.policyId, props.action).subscribe(res => {
            localStorage.setItem('endorsementId', res?.id);
            handleClose();
            props.onComplete();
          });
        // }
      }
    } else {
      if (quotationId && selectedFile) {
        const formData = new FormData();
        formData.append('filePart', selectedFile);

        quotationService.uploadTemplate(formData, quotationId, pageRequest).subscribe(res => {
          handleClose();
          props.onComplete();
        });
      }
    }
  };

  return (
    <Dialog fullWidth={true} maxWidth="xl" open={props.open} onClose={handleClose} aria-labelledby="max-width-dialog-title">
      <DialogTitle id="max-width-dialog-title">Member Upload</DialogTitle>
      <DialogContent>
        {/* <AppBar position="static">
          <Tabs value={tabvalue} onChange={handleTabChange} aria-label="simple tabs example">
            <Tab textColor='inherit' label="File Upload" {...a11yProps(0)} />
          </Tabs>
        </AppBar>
        <TabPanel value={tabvalue} index={0}> */}
        <DropzoneArea
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
          previewChipProps={{ classes: { root: classes.previewChip } }}
          previewText="Selected files"
          onChange={handleChange.bind(this)}
          filesLimit={1}
        />
        {/* </TabPanel> */}
      </DialogContent>
      <DialogActions>
        <Button onClick={doUpload} type='button' variant="contained" color="secondary" startIcon={<CloudUploadIcon />}>
          Upload
        </Button>
        <Button onClick={handleClose} className='p-button-text' color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}
