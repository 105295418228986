import { makeStyles } from '@material-ui/core/styles';
import 'date-fns';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { CategoryService, ProviderTypeService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { ProvidersService } from '../../remote-api/api/provider-services';
import { FettleDataGrid } from '../../shared-components';
import ProviderBlacklistModal from './modals/provider.blackist.modal';
import ProviderCategoryHistoryModal from './modals/provider.category.history.modal';
import ProviderCategoryListModal from './modals/provider.category.list.modal';
import ProviderCategorizeModal from './modals/provider.category.modal';
import ProviderUnBlacklistModal from './modals/provider.unblacklist.modal';
import { Button } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ProviderContractDetailsModal from './modals/providercontractDetails.modal';
import { Toast } from 'primereact/toast';

const PAGE_NAME = 'PROVIDER';
const roleService = new RoleService();
const providertypeservice = new ProviderTypeService();
const ModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: '#FFF',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  categoryButton: {
    marginLeft: '5px',
    marginBottom: '5px',
  },
}));

const providerService = new ProvidersService();
const planservice = new PlanService();
const categoryservice = new CategoryService();

let pls$ = planservice.getPlans();
let ct$ = categoryservice.getCategories();

export default function ProviderPendingListComponent() {
  const history = useHistory();
  const [rows, setRows] = React.useState();
  const [planList, setPlanList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [data, setData] = React.useState([]);
  const [selectionBlacklistMenuDisabled, setSelectionBlacklistMenuDisabled] = React.useState(true);
  const [selectionUnBlacklistMenuDisabled, setSelectionUnBlacklistMenuDisabled] = React.useState(true);
  const [reloadTable, setReloadTable] = React.useState(false);
  const [categoryModal, setCategoryModal] = React.useState(false);
  const [categoryData, setCategoryData] = React.useState([]);
  const toast = React.useRef(null);

  const [state, setState] = React.useState({
    action: '',
    openBlacklistModal: false,
    openUnBlacklistModal: false,
    openContractDetailsModal: false,
    openCategoryModal: false,
    openCategoryListModal: false,
    providerCategoryHistorys: [],
    providerIds: [],
    blackListedProviderids: [],
  });
  const [provider, setProvider] = React.useState('');
  const dataSource$ = (
    pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
    },
  ) => {
    pageRequest.sort = ['rowLastUpdatedDate dsc'];
    if (pageRequest.searchKey) {
      pageRequest['code'] = pageRequest.searchKey.trim();
      pageRequest['type'] = pageRequest.searchKey.trim();
      pageRequest['name'] = pageRequest.searchKey.trim();
      pageRequest['contactNo'] = pageRequest.searchKey.trim();
    }
    delete pageRequest.searchKey;
    return providerService
      .getPendingProviders(pageRequest)
      .pipe(
        map(data => {
          let content = data.content;
          setData(content);
          let records = content.map(item => {
            item['providerBasicDetails']['primaryContact'] = item.providerBasicDetails.contactNos[0].contactNo;
            item['blacklist'] = item.blackListed ? 'Yes' : 'No';
            item['category'] = item?.providerCategoryHistorys[0]?.categoryId;
            return item;
          });
          data.content = records;
          return data;
        }),
      )
      .pipe(
        switchMap(data => {
          return providertypeservice.getProviderTypes().pipe(
            map(pt => {
              data.content.forEach(pr => {
                pt.content.forEach(providertype => {
                  if (pr.providerBasicDetails.type === providertype.code) {
                    pr['providerTypeName'] = providertype.name;
                  }
                });
              });
              return data;
            }),
          );
        }),
      );
  };

  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.name,
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable2(pls$, setPlanList);
  useObservable(ct$, setCategoryList);

  const classes = useStyles();

  const handleOpen = () => {
    history.push('/provider?mode=create');
  };

  const closeBlacklistModal = () => {
    setState({
      ...state,
      openBlacklistModal: false,
    });
  };

  const closeUnBlacklistModal = () => {
    setState({
      ...state,
      openUnBlacklistModal: false,
    });
  };

  const closeContractDetailsModal = () => {
    setState({
      ...state,
      openContractDetailsModal: false,
    });
  };
  const closeCategorizeModal = () => {
    setState({
      ...state,
      openCategoryModal: false,
    });
  };

  const closeCategoryListModal = () => {
    setState({
      ...state,
      openCategoryListModal: false,
    });
  };

  const closeCategoryHistoryModal = () => {
    setCategoryModal(false);
  };

  const openEditSection = provider => {
    history.push(`/provider/${provider.id}?mode=viewOnly`);
  };

  const handleSelectedRows = selectedProviders => {
    if (selectedProviders.length == 0) {
      setSelectionBlacklistMenuDisabled(true);
      setSelectionUnBlacklistMenuDisabled(true);
    } else {
      let sp = [];
      let blp = [];
      let filteredLength = selectedProviders.filter(p => !p.blackListed).length;
      let blFilterdLength = selectedProviders.filter(p => p.blackListed).length;
      setSelectionBlacklistMenuDisabled(filteredLength != selectedProviders.length);
      setSelectionUnBlacklistMenuDisabled(blFilterdLength != selectedProviders.length);
      sp = selectedProviders.filter(p => !p.blackListed).map(ele => ele.id);
      blp = selectedProviders.filter(p => p.blackListed).map(ele => ele.id);
      setState({
        ...state,
        providerIds: sp,
        blackListedProviderids: blp,
      });
      setProvider(blp.toString());
    }
  };
  const handleBlacklistSubmit = payload => {
    payload['providerIds'] = state.providerIds;
    providerService.blacklistProvider(payload).subscribe(res => {
      closeBlacklistModal();
      setReloadTable(true);
    });
  };

  const handleUnBlacklistSubmit = payload => {
    payload['providerIds'] = state.blackListedProviderids;
    providerService.unblacklistProvider(payload).subscribe(res => {
      closeBlacklistModal();
      setReloadTable(true);
    });
  };
  const handleContractDetails = payload => {
    providerService.getProviderAllDetails(payload, state?.providerIds.toString() || provider).subscribe(res => {
      closeContractDetailsModal();
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'SuccessFully Added',
        life: 3000,
      });
      setReloadTable(true);
    });
  };

  const handleCategorizeSubmit = payload => {
    closeCategorizeModal();
    payload['providerIds'] = state.providerIds;
    providerService.categorizeProvider(payload).subscribe(res => {
      closeCategorizeModal();
      setReloadTable(true);
    });
  };

  const openBlacklist = e => {
    setState({
      ...state,
      openBlacklistModal: true,
    });
  };

  const openUnBlacklist = e => {
    setState({
      ...state,
      openUnBlacklistModal: true,
    });
  };
  const openContractDetails = e => {
    setState({
      ...state,
      openContractDetailsModal: true,
    });
  };
  const openCategorize = provider => {
    setState({
      ...state,
      // providerIds: provider?.id,
      openCategoryModal: true,
    });
  };

  const showCategoryList = val => {
    val.providerCategoryHistorys.forEach((value, i) => {
      value['startDate'] = new Date(value.startDate);
      value['id'] = i;
      planList.forEach(pln => {
        if (value.planId === pln.id) {
          value['planName'] = pln.name;
        }
      });
      categoryList.forEach(cat => {
        if (value.categoryId === cat.id) {
          value['catName'] = cat.name;
        }
      });
    });
    setState({
      ...state,
      openCategoryListModal: true,
      providerCategoryHistorys: val.providerCategoryHistorys,
    });
  };

  const handleClickForAppoveOpen = provider => {
    providerService.approveProvider(provider.id).subscribe(res => {
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Provider Approved',
        life: 3000,
      });
      setReloadTable(true);
    });
  };

  const actionBtnList = [
    {
      key: 'update_provider',
      icon: 'pi pi-user-edit',
      className: 'ui-button-warning',
      onClick: openEditSection,
      className: classes.categoryButton,
    },
    {
      key: 'update_quotation',
      icon: 'pi pi-check',
      // className: classes.approvedButton,
      className: classes.categoryButton,
      onClick: handleClickForAppoveOpen,
      tooltip: 'Approve',
    },
  ];
  const xlsColumns = [
    'providerBasicDetails.name',
    'providerBasicDetails.code',
    'providerTypeName',
    'providerBasicDetails.primaryContact',
    'category',
    'blacklist'
  ];
  
  const configuration = {
    enableSelection: true,
    scrollHeight: '300px',
    pageSize: 10,
    // actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', openEditSection),
    actionButtons: roleService.checkActionPermission(PAGE_NAME, '', () => {}, actionBtnList),
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Provider Management',
      enableGlobalSearch: true,
      searchText: 'Search by code, name, type',
      onSelectionChange: handleSelectedRows,
      selectionMenus: [
        {
          icon: '',
          text: 'Blacklist',
          disabled: selectionBlacklistMenuDisabled,
          onClick: openBlacklist,
        },
        {
          icon: '',
          text: 'Categorize',
          disabled: selectionBlacklistMenuDisabled,
          onClick: openCategorize,
        },
        {
          icon: '',
          text: 'Un-Blacklist',
          disabled: selectionUnBlacklistMenuDisabled,
          onClick: openUnBlacklist,
        },
        ,
        {
          icon: '',
          text: 'Add Contract Details',
          disabled: selectionUnBlacklistMenuDisabled,
          onClick: openContractDetails,
        },
      ],
      selectionMenuButtonText: 'Action',
    },
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCategoryClick = e => {
    setCategoryModal(true);
    let temp = data.filter(item => {
      return item.id === e.target.title;
    });
    setCategoryData(temp[0]?.providerCategoryHistorys);
  };

  const columnsDefinations = [
    { field: 'providerBasicDetails.name', headerName: 'Provider Name' },
    { field: 'providerBasicDetails.code', headerName: 'Provider Code' },
    { field: 'providerTypeName', headerName: 'Provider Type' },
    { field: 'providerBasicDetails.primaryContact', headerName: 'Contact Number' },
    {
      field: 'category',
      headerName: 'Category',
      body: rowData => (
        <span
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          title={rowData.id}
          onClick={e => onCategoryClick(e)}>
          {rowData.category}
        </span>
      ),
    },
    { field: 'blacklist', headerName: 'Blacklisted' },
  ];

  const OpenModal = () => {};

  return (
    <div>
      <Toast ref={toast} />
      <FettleDataGrid
        $dataSource={dataSource$}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        config={configuration}
        reloadTable={reloadTable}
      />

      {state.openBlacklistModal ? (
        <ProviderBlacklistModal
          closeBlacklistModal={closeBlacklistModal}
          openBlacklistModal={state.openBlacklistModal}
          handleBlacklistSubmit={handleBlacklistSubmit}
        />
      ) : null}
      {state.openUnBlacklistModal ? (
        <ProviderUnBlacklistModal
          closeUnBlacklistModal={closeUnBlacklistModal}
          openUnBlacklistModal={state.openUnBlacklistModal}
          handleUnBlacklistSubmit={handleUnBlacklistSubmit}
        />
      ) : null}
      {state.openContractDetailsModal ? (
        <ProviderContractDetailsModal
          closeContractDetailsModal={closeContractDetailsModal}
          openContractDetailsModal={state.openContractDetailsModal}
          handleContractDetails={handleContractDetails}
        />
      ) : null}
      {state.openCategoryModal ? (
        <ProviderCategorizeModal
          closeCategorizeModal={closeCategorizeModal}
          openCategoryModal={state.openCategoryModal}
          handleCategorizeSubmit={handleCategorizeSubmit}
          providerIds={state.providerIds}
          planList={planList}
          categoryList={categoryList}
        />
      ) : null}

      {state.openCategoryListModal ? (
        <ProviderCategoryListModal
          openCategoryListModal={state.openCategoryListModal}
          closeCategoryListModal={closeCategoryListModal}
          planList={planList}
          categoryList={categoryList}
          providerCategoryHistorys={state.providerCategoryHistorys}
        />
      ) : null}

      {categoryModal ? (
        <ProviderCategoryHistoryModal
          openCategoryListModal={categoryModal}
          closeCategoryListModal={closeCategoryHistoryModal}
          categoryList={categoryData}
        />
      ) : null}
      {/* {
        < Modal
          open={categoryModal}
      onClose={() => { setCategoryModal(false) }}
        >
      <Box style={ModalStyle}>
        <FettleDataGrid
          $dataSource={dataSource$}
          columnsDefination={columnsDefinations}
          // onEdit={openEditSection}
          config={configuration}
        // reloadTable={reloadTable}
        />
      </Box>
    </Modal>
      } */}
    </div>
  );
}
