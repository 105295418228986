import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AgentsService, GroupTypeService, UsersService } from '../../remote-api/fettle-remote-api';
import { previousSaturday } from 'date-fns';
import { HierarchyService } from '../../remote-api/api/hierarchy-services';
import TreeStructure from './components/target.tree.componet';

const useStyles = makeStyles(theme => ({
  table: {
    minWidth: 650,
  },
  selectYear: {
    margin: theme.spacing(2),
    minWidth: 270,
  },
  submitButton: {
    marginLeft: theme.spacing(2),
  },
}));

const grouptypeService = new GroupTypeService();
let gt$ = grouptypeService.getGroupTypes();
const usersService = new UsersService();
let usersService$ = usersService.getUsers();
const orgtypeservice = new HierarchyService();
const agentsService = new AgentsService();

const TargetComponent = () => {
  const classes = useStyles();
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [groupTypes, setGroupTypes] = React.useState([]);

  const [hierarchyData, setHierarchyData] = React.useState([]);
  const [userList, setUsersList] = React.useState([]);

  const years = [currentYear - 2, currentYear - 1, currentYear, currentYear + 1, currentYear + 2];

  const months = Array.from({ length: 12 }, (_, index) =>
    new Date(0, index).toLocaleString('en-US', { month: 'long' }).toUpperCase(),
  );

  // console.log('groupTypes ', groupTypes);
  const formatDta = (data, userArray) => {
    data &&
      data.forEach(dt => {
        dt['child'] = dt.childPositions;
        if (dt?.user?.userId) {
          userArray &&
            userArray.forEach(usr => {
              if (usr?.id === dt?.user?.userId) {
                dt['employeeList'] = [{ id: usr?.id, name: usr?.username }];
                dt['expression'] = usr?.username;
              }
            });
        }
        if (dt?.childPositions?.length !== 0) {
          formatDta(dt?.childPositions, userArray);
        }
      });
    return data;
  };

  // console.log('hierarchyData ', hierarchyData);
  // console.log('userList ', userList);

  const useObservableTree = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(res => {
        let uList = [];
        res.forEach((usr, i) => {
          let fname = usr.firstName ? usr.firstName : '';
          let lname = usr.lastName ? usr.lastName : '';
          let obj = {
            type: 'USER',
            id: usr.userName,
            username: usr.userName,
            name: fname + ' ' + lname,
          };
          uList.push(obj);
        });
        agentsService.getAgents().subscribe(agentlist => {
          let agList = [];
          agentlist.content.forEach(ag => {
            let obj = {
              type: 'AGENT',
              id: ag.id,
              username: ag.agentBasicDetails.name,
            };
            agList.push(obj);
          });

          let arr = [...uList, ...agList];
          setter(arr);
          orgtypeservice.getSampleData().subscribe(result => {
            let arrval = formatDta(result, arr);

            setHierarchyData(arrval);
          });
        });
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservableTree(usersService$, setUsersList);

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(prv => [{ id: 0, code: 'RETAIL', name: 'Retail' }, ...result.content]);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable(gt$, setGroupTypes);

  

  const handleYearChange = year => {
    setSelectedYear(year);
    // setTableData(
    //   months.map((month, index) => ({
    //     id: index + 1,
    //     period: `${month}-${year}`,
    //     retail: null,
    //     corporate: null,
    //     sme: null,
    //     community: null,
    //   })),
    // );
  };

  return (
    <>
      <Select value={selectedYear} onChange={e => handleYearChange(e.target.value)} className={classes.selectYear}>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
      <TreeStructure
        data={hierarchyData}
        groupTypes={groupTypes}
        months={months}
        selectedYear={selectedYear}
      />
    </>
  );
};

export default TargetComponent;
