import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { useLocation } from 'react-router-dom';
import AgentsRoutersComponent from '../modules/agent-service/root.component';
import BankRoutersComponent from '../modules/bank-management/root.component';
import ClaimsRoutersComponent from '../modules/claim-management/claim.router.component';
import ClientsRoutersComponent from '../modules/client-management/root.component';
import CodeGenerationComponent from '../modules/code-generation/code.generation.component';
import EndorsementRoutersComponent from '../modules/endorsement-management/root.component';
import HRRoutersComponent from '../modules/hr-management/root.component';
import InvoiceRoutersComponent from '../modules/invoice-management/root.component';
import MastersRoutersComponent from '../modules/master-data-management/router.component';
import RenewalRoutersComponent from '../modules/renewal-management/root.component';
import MemberUploadRouterComponent from '../modules/member-upload-management/root.component';
import PlansRoutersComponent from '../modules/plan-management/root.component';
import PremiumRoutersComponent from '../modules/premium-management/root.component';
import FundsRoutersComponent from '../modules/fund-management/root.component';
import ProductsRoutersComponent from '../modules/product-management/root.component';
import ProposerRoutersComponent from '../modules/policy-management/root.component';
import ProvidersRoutersComponent from '../modules/provider-service/root.component';
import QuotationRouterComponent from '../modules/quotation-service-ui/src/root.component';
import ReceiptRoutersComponent from '../modules/receipt-management/root.component';
import TaxRoutersComponent from '../modules/tax-management/root.component';
import UsersRoutersComponent from '../modules/user-management/router.component';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Login from './Auth/Login';
import Register from './Auth/Register';
import Error404 from './Pages/404';
import SamplePage from './Pages/SamplePage';
import UnauthorizedFettle from './Pages/Unauthorized';
import ReadyForPaymentRoutersComponent from '../modules/claim-management/ready-for-payment/root.component';
import FeesRoutersComponent from '../modules/fees-management/root.component';
import MemberBalanceRoutersComponent from '../modules/member-balance-management/root.component';
import MemberEnquiryComponent from '../modules/member-enquiry/root.component';
import GuidelinesRouterComponent from '../modules/guidelines/root.component';
import ClaimsReportsRouterComponent from '../modules/claim-reports/root.component';
import QuestionnaireRoutersComponent from '../modules/questionnaire-management/root.component';
import UnderwritingsReportsRouterComponent from '../modules/underwritings-reports/root.component';
import BranchRoutersComponent from '../modules/branch-management/root.component';
import SLAConfigurationRoutersComponent from '../modules/sla-configuration/root.component';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  /* const { authUser } = useSelector(({ auth }) => auth); */
  const location = useLocation();
  const userType = localStorage.getItem('userType');

  if (location.pathname === '' || location.pathname === '/') {
    if (userType === 'DOCTOR' || userType === 'SURVEILLANCE') {
      return <Redirect to={'/claims/claims-preauth?mode=viewList'} />;
    } else {
      return <Redirect to={'/dashboard'} />;
    }
  } /*  else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/dashboard'} />;
  } */

  return (
    <React.Fragment>
      <Switch>
        <Route path="/dashboard" component={SamplePage} />
        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Route path="/client" component={ClientsRoutersComponent} />
        <Route path="/funds" component={FundsRoutersComponent} />
        <Route path="/fees" component={FeesRoutersComponent} />
        <Route path="/products" component={ProductsRoutersComponent} />
        <Route path="/masters" component={MastersRoutersComponent} />
        <Route path="/premium" component={PremiumRoutersComponent} />
        <Route path="/agents" component={AgentsRoutersComponent} />
        <Route path="/renewals" component={RenewalRoutersComponent} />
        <Route path="/plans" component={PlansRoutersComponent} />
        <Route path="/branch" component={BranchRoutersComponent} />
        <Route path="/provider" component={ProvidersRoutersComponent} />
        <Route path="/bank-management" component={BankRoutersComponent} />
        <Route path="/hr" component={HRRoutersComponent} />
        <Route path="/receipts" component={ReceiptRoutersComponent} />
        <Route path="/invoices" component={InvoiceRoutersComponent} />
        <Route path="/policies" component={ProposerRoutersComponent} />
        <Route path="/endorsements" component={EndorsementRoutersComponent} />
        <Route path="/code-generator" component={CodeGenerationComponent} />
        <Route path="/member-upload" component={MemberUploadRouterComponent} />
        <Route path="/questionnaire" component={QuestionnaireRoutersComponent} />
        <Route path="/underwriting/guidelines" component={GuidelinesRouterComponent} />
        <Route path="/quotations" component={QuotationRouterComponent} />
        <Route path="/claims" component={ClaimsRoutersComponent} />
        <Route path="/ready-for-payment" component={ReadyForPaymentRoutersComponent} />
        <Route path="/member-balance" component={MemberBalanceRoutersComponent} />
        <Route path="/member-enquiry" component={MemberEnquiryComponent} />
        <Route path="/report/underwritings" component={UnderwritingsReportsRouterComponent} />
        <Route path="/report/claims" component={ClaimsReportsRouterComponent} />
        {/* <Route path="/member-enquiry/view-policy-history/:id" component={ViewPolicyHistory} /> */}

        <Route path="/taxes" component={TaxRoutersComponent} />
        <Route path="/user-management" component={UsersRoutersComponent} />
        <Route path="/sla/configuration" component={SLAConfigurationRoutersComponent} />
        <Route path="/unauthorized" component={UnauthorizedFettle} />
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
