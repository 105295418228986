import DateFnsUtils from '@date-io/date-fns';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MuiAlert from '@material-ui/lab/Alert';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { BankService } from '../../remote-api/api/banks-services';
import { ClientService, ProspectService } from '../../remote-api/api/client-services';
import {
  AddressService,
  CurrencyService,
  DenominationService,
  TransactionModeService,
} from '../../remote-api/api/master-services';
import { ReceiptService } from '../../remote-api/api/receipts-services';
import { InvoiceService } from '../../remote-api/fettle-remote-api';
import ReceiptClientModal from './modals/receipts.client.modal.component';
import Asterisk from '../../shared-components/components/red-asterisk';

const validationSchema = yup.object({
  depositBank: yup.string('Select a bank').required('Bank is required'),
  depositAccountNo: yup.string('Enter Account number').required('Account No. is required'),
  fundAmount: yup.string('Enter fund amount').required('Fund amount is required'),
  premiumAmount: yup.string('Enter premium amount').required('Premium amount is required'),
});

const clientservice = new ClientService();
const prospectservice = new ProspectService();
const receiptservice = new ReceiptService();
const addressservice = new AddressService();
const bankservice = new BankService();
const currencyservice = new CurrencyService();
const tranService = new TransactionModeService();
const denoService = new DenominationService();
const invoiceservice = new InvoiceService();

let addr$ = addressservice.getAddressConfig();
let bnk$ = bankservice.getBanks();
let cs$ = currencyservice.getCurrencies();
let ts$ = tranService.getTransactionModes();
let ds$ = denoService.getDenominations();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: '90%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightBold,
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  AccordionSummary: {
    backgroundColor: theme.palette.background.default,
  },
  disabled: {},
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
export default function ReceiptDetails(props) {
  const query2 = useQuery1();
  const { id } = useParams();
  const history = useHistory();
  const classes = useStyles();
  const [openClientModal, setOpenClientModal] = React.useState(false);
  const [bankList, setBankList] = React.useState([]);
  const [currencyList, setCurrencyList] = React.useState([]);
  const [transactionModeList, setTransactionModeList] = React.useState([]);
  const [selectedTransactionMode, setSelectedTransactionMode] = React.useState([]);
  const [openSnack, setOpenSnack] = React.useState(false);
  const [openSnack2, setOpenSnack2] = React.useState(false);
  const [expandClientDetails, setExpandClientDetails] = React.useState(false);
  const [expandTransactionDetails, setExapandTransactionDetails] = React.useState(false);
  const [denominations, setDenomination] = React.useState([]);

  const [transactionList, setTransactionList] = React.useState([
    {
      transactionMode: '',
      transactionModeName: '',
      transactionAmount: 0,
      transactionCurrency: '',
      exchangeRate: 0,
      exchangeAmount: 0,
      denominations: null,
      chequeDetails: null,
      mpesaDetail: null,
      eftDetail: null,
      demandDraftDetail: null,
      creditCardDetail: null,
    },
  ]);
  const [clientData, setClientData] = React.useState({
    clientName: '',
    clientMobile: '',
    clientEmail: '',
    clientId: '',
    clientType: '',
  });
  const [addressConfig, setAddressConfig] = React.useState([]);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [revertedMessage, setRevertedMessage] = React.useState(false);

  const handleInvoiceDate = date => {
    setSelectedDate(date);

    const timestamp = new Date(date).getTime();

    formik.setFieldValue('receiptDate', timestamp);
  };

  const handleChequeDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].chequeDetails['chequeDateVal'] = date;
    list[i].chequeDetails['chequeDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleCheque = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].chequeDetails[name] = value;

    setTransactionList(list);
  };

  const handleDraftDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].demandDraftDetail['draftDateVal'] = date;
    list[i].demandDraftDetail['demandDraftDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleDraft = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].demandDraftDetail[name] = value;

    setTransactionList(list);
  };

  const handleCardDetails = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].creditCardDetail[name] = value;

    setTransactionList(list);
  };

  const handleDebitCardDetails = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].debitCardDetail[name] = value;

    setTransactionList(list);
  };

  const handleTransactionDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].creditCardDetail['cardTransactionDateVal'] = date;
    list[i].creditCardDetail['transactionDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleDebitTransactionDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].debitCardDetail['cardTransactionDateVal'] = date;
    list[i].debitCardDetail['transactionDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleExpireDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].creditCardDetail['cardExpireDateVal'] = date;
    list[i].creditCardDetail['expireDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleDebitExpireDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].debitCardDetail['cardExpireDateVal'] = date;
    list[i].debitCardDetail['expireDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleMPESADate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].mpesaDetail['mpesaDateVal'] = date;
    list[i].mpesaDetail['mpsaDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleMPESA = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].mpesaDetail[name] = value;
    setTransactionList(list);
  };

  const handleEFTDate = (date, i) => {
    // const { name, value } = e.target;
    const list = [...transactionList];
    list[i].eftDetail['eftDateVal'] = date;
    list[i].eftDetail['eftDate'] = new Date(date).getTime();

    setTransactionList(list);
  };

  const handleEFT = (e, i) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].eftDetail[name] = value;
    setTransactionList(list);
  };

  const handleDenominationAmount = (e, i, j) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[i].denominations[j][name] = Number(value);

    setTransactionList(list);
  };
  // Transaction List functions
  const handleInputChangeTransaction = (e, index) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    list[index][name] = value;
    setTransactionList(list);
  };

  const handleTransactionModeChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...transactionList];
    const list2 = [...selectedTransactionMode];
    if (list2.indexOf(value) === -1) {
      //Not in the array, the value is pushed to the index assigned for that transaction row, so that if he changes again, it will overwrite the value in that position
      list2[index] = value;
      setSelectedTransactionMode(list2);
    } else {
      //In the array!
    }
    list[index][name] = value;

    /**
     * Get the Transaction Mode name
     */
    const selectedMode = transactionModeList.filter(tm => tm.code === value);
    if (selectedMode.length > 0) {
      list[index]['transactionModeName'] = selectedMode[0].name;
    }

    // cash
    if (list[index]['transactionModeName'].toLowerCase() === 'cash') {
      let arr = [];
      denominations.forEach(ele => {
        arr.push(ele);
      });

      list[index]['denominations'] = arr;
      list[index]['chequeDetails'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['eftDetail'] = null;
      list[index]['creditCardDetail'] = null;
      list[index]['demandDraftDetail'] = null;
    }
    //cheque
    if (list[index]['transactionModeName'].toLowerCase() === 'cheque') {
      list[index]['chequeDetails'] = {
        chequeNo: '',
        chequeDateVal: new Date(),
        chequeDate: new Date().getTime(),
        bankName: '',
        otherBankName: '',
        branch: '',
      };
      list[index]['denominations'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['eftDetail'] = null;
      list[index]['creditCardDetail'] = null;
      list[index]['demandDraftDetail'] = null;
    }
    //MPESA
    if (list[index]['transactionModeName'].toLowerCase() === 'mpesa') {
      list[index]['mpesaDetail'] = {
        mpsaNo: '',
        mpesaDateVal: new Date(),
        mpsaDate: new Date().getTime(),
      };
      list[index]['denominations'] = null;
      list[index]['chequeDetails'] = null;
      list[index]['eftDetail'] = null;
      list[index]['creditCardDetail'] = null;
      list[index]['demandDraftDetail'] = null;
    }

    //EFT
    if (list[index]['transactionModeName'].toLowerCase() === 'eft') {
      list[index]['eftDetail'] = {
        eftNo: '',
        eftDateVal: new Date(),
        eftDate: new Date().getTime(),
        bankName: '',
        otherBankName: '',
      };
      list[index]['denominations'] = null;
      list[index]['chequeDetails'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['demandDraftDetail'] = null;
      list[index]['creditCardDetail'] = null;
    }

    //DD
    if (list[index]['transactionModeName'].toLowerCase() === 'dd') {
      list[index]['demandDraftDetail'] = {
        demandDraftNo: '',
        draftDateVal: new Date(),
        demandDraftDate: new Date().getTime(),
        bankName: '',
        otherBankName: '',
        branch: '',
      };
      list[index]['denominations'] = null;
      list[index]['chequeDetails'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['eftDetail'] = null;
      list[index]['creditCardDetail'] = null;
    }

    //CREDIT CARD
    if (list[index]['transactionModeName'].toLowerCase() === 'credit card') {
      list[index]['creditCardDetail'] = {
        cardNumber: '',
        cardTransactionDateVal: new Date(),
        transactionDate: new Date().getTime(),
        cardExpireDateVal: new Date(),
        expireDate: new Date().getTime(),
        bankName: '',
        otherBankName: '',
        branch: '',
        cardType: '',
      };
      list[index]['denominations'] = null;
      list[index]['chequeDetails'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['eftDetail'] = null;
      list[index]['demandDraftDetail'] = null;
    }
    //DEBIT CARD
    if (list[index]['transactionModeName'].toLowerCase() === 'debit card') {
      list[index]['debitCardDetail'] = {
        cardNumber: '',
        cardTransactionDateVal: new Date(),
        transactionDate: new Date().getTime(),
        cardExpireDateVal: new Date(),
        expireDate: new Date().getTime(),
        bankName: '',
        otherBankName: '',
        branch: '',
        cardType: '',
      };
      list[index]['denominations'] = null;
      list[index]['chequeDetails'] = null;
      list[index]['mpesaDetail'] = null;
      list[index]['eftDetail'] = null;
      list[index]['demandDraftDetail'] = null;
    }
    setTransactionList(list);
  };

  const getdisabledStatus = value => {
    if (selectedTransactionMode.indexOf(value) > -1) {
      return true;
    } else {
      return false;
    }
  };

  const handleRemoveClickTransaction = index => {
    const list = [...transactionList];
    const list2 = [...selectedTransactionMode];
    list.splice(index, 1);
    list2.splice(index, 1);
    setSelectedTransactionMode(list2);
    setTransactionList(list);
  };

  const handleAddClickTransaction = () => {
    setTransactionList([
      ...transactionList,
      {
        transactionMode: '',
        transactionAmount: 0,
        transactionCurrency: '',
        exchangeRate: 0,
        exchangeAmount: 0,
        denominations: null,
        chequeDetails: null,
        mpesaDetail: null,
        eftDetail: null,
        demandDraftDetail: null,
        creditCardDetail: null,
      },
    ]);
  };
  // Transaction List functions

  const formik = useFormik({
    initialValues: {
      receiptDate: new Date().getTime(),
      receiptType: 'receiptWithoutInvoice',
      clientOrProspectId: '',
      clientOrProspectType: '',
      type: '',
      premiumAmount: 0,
      fundAmount: 0,
      depositAccountNo: '',
      depositBank: '',
      invoiceNo: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      handleSubmit();
    },
  });

  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            tableArr.push({
              name: ele.bankBasicDetails.bankName,
              id: ele.id,
            });
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable3 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        let tableArr = [];

        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            if (ele.flag) {
              tableArr.push({
                currencyValue: Number(ele.currencyValue),
                id: ele.id,
                noOfCurrency: 0,
              });
            }
          });
        }
        setter(tableArr);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  useObservable3(ds$, setDenomination);
  useObservable2(bnk$, setBankList);
  useObservable(cs$, setCurrencyList);
  useObservable(ts$, setTransactionModeList);

  useEffect(() => {
    let subscription = addr$.subscribe(result => {
      if (result && result.length !== 0) {
        result.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.forEach((field, j) => {
            // let fname = "field"+i+j;
            field['value'] = '';
            if (field.sourceId !== null && field.sourceId !== '') {
              field['sourceList'] = [];
            }
            if (field.type === 'dropdown' && field.sourceId === null) {
              if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
              }
              // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
              //   field['sourceList'] = [];
              // }
            }
          });
        });

        setAddressConfig(result);

        result.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.map((field, j) => {
            //   frmObj[field.fieldName] = field.defaultValue;
            if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
              addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
                // field.sourceList =res.content;
                const list = [...result];
                list[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                setAddressConfig(list);
                // frmLst[field.fieldName] = res.content;
              });
            }
          });
        });
      }
    });
    return () => subscription.unsubscribe();
  }, [addr$, setAddressConfig]);

  const handleopenClientModal = () => {
    setOpenClientModal(true);
  };

  const handleCloseClientModal = () => {
    setOpenClientModal(false);
  };

  const fetchInvoiceDetails = () => {
    let payload = {
      invoiceNumber: formik.values.invoiceNo,
    };
    invoiceservice.getInvoice(payload).subscribe(res => {
      let inv = res.content[0];
      formik.setFieldValue('premiumAmount', inv.totalAmountWithTax);
      if (inv.clientOrProspectId) {
        clientservice.getClientDetails(inv.clientOrProspectId).subscribe(client => {
          if (client) {
            setClientData({
              ...setClientData,
              clientName: client.clientBasicDetails.displayName,
              clientMobile: client.clientBasicDetails.contactNos[0].contactNo,
              clientEmail: client.clientBasicDetails.emails[0].emailId,
              clientId: client.id,
              clientType: 'Client',
            });
            populateDynamicAddress(client, addressConfig);
          }
        });
      }
    });
  };

  const handleSubmitClientModal = item => {
    setClientData({
      ...setClientData,
      clientName: item.name,
      clientMobile: item.contactNo,
      clientEmail: item.email,
      clientId: item.id,
      clientType: item.clientType,
    });
    populateDynamicAddress(item, addressConfig);
    setOpenClientModal(false);
  };

  const callAPiFunc = (field, prop, resultarr, addrrList) => {
    addrrList.forEach((pr, i) => {
      pr.addressConfigurationFieldMappings.forEach((fi, j) => {
        if (fi.fieldName === prop.dependOnfields[0]) {
          resultarr.push(fi.value);
          if (pr.dependOnfields !== null) {
            callAPiFunc(fi, pr, resultarr, addrrList);
          }
        }
      });
    });

    return resultarr;
  };

  const populateDynamicAddress = (item, addressConfigList) => {
    if (addressConfigList && addressConfigList.length !== 0) {
      let addrrList = [...addressConfigList];

      item.clientAddress.addresses.forEach(val => {
        addrrList.forEach((prop, i) => {
          prop.addressConfigurationFieldMappings.forEach((field, j) => {
            if (Object.keys(val.addressDetails)[0] === field.fieldName) {
              field['value'] = val.addressDetails[field.fieldName];
            }
          });
        });
      });

      // setAddressConfig(addrrList);

      addrrList.forEach((prop, i) => {
        prop.addressConfigurationFieldMappings.forEach((field, j) => {
          if (field.type === 'dropdown' && field.dependsOn !== '' && prop.dependOnfields !== null) {
            let arr = [];
            let dArr = callAPiFunc(field, prop, arr, addrrList);

            const word = '{code}';
            let apiURL = field.modifyApiURL;
            dArr.forEach(cd => {
              apiURL = apiURL.slice(0, apiURL.lastIndexOf(word)) + apiURL.slice(apiURL.lastIndexOf(word)).replace(word, cd);
            });
            addressservice.getSourceList(apiURL).subscribe(res => {
              addrrList[i].addressConfigurationFieldMappings[j].sourceList = res.content;

              setAddressConfig(addrrList);
            });
          } else {
            // setAddressConfig(addrrList);
          }
        });
      });

      // setAddressConfig(addrrList);
    }
  };

  const handleSubmit = () => {
    let totalTrans = 0;
    transactionList.forEach(ele => {
      ele.transactionAmount = Number(ele.transactionAmount);
      ele.exchangeRate = Number(ele.exchangeRate);
      ele.exchangeAmount = Number(ele.exchangeAmount);
      totalTrans = totalTrans + Number(ele.transactionAmount);
      if (ele.transactionMode === 'TM278553') {
        let deTotal = 0;
        ele.denominations.forEach(de => {
          deTotal = deTotal + Number(de.noOfCurrency) * Number(de.currencyValue);
        });
        if (deTotal !== Number(ele.transactionAmount)) {
          setOpenSnack2(true);
          return;
        }
      }
    });
    let fp = Number(formik.values.premiumAmount) + Number(formik.values.fundAmount);
    if (totalTrans !== fp) {
      setOpenSnack(true);
      return;
    }

    let payload = {
      receiptDate: new Date(selectedDate).getTime(),
      receiptType: formik.values.receiptType,
      clientOrProspectId: clientData.clientId,
      clientOrProspectType: clientData.clientType,
      premiumAmount: Number(formik.values.premiumAmount),
      fundAmount: Number(formik.values.fundAmount),
      depositAccountNo: formik.values.depositAccountNo,
      depositBank: formik.values.depositBank,
      transactionDetails: transactionList,
      invoiceNo: formik.values.receiptType === 'receiptWithInvoice' ? formik.values.invoiceNo : null,
    };

    receiptservice.saveReceipt(payload).subscribe(res => {
      history.push(`/receipts?mode=viewList`);
      // window.location.reload();
    });
  };

  React.useEffect(() => {
    if (id) {
      populateData(id);
    }
  }, [id]);

  const populateData = id => {
    setExapandTransactionDetails(true);
    setExpandClientDetails(true);
    if (id) {
      receiptservice.getReceiptDetails(id).subscribe(res => {
        formik.setFieldValue('receiptDate', res.receiptDate);
        formik.setFieldValue('receiptType', res.receiptType);
        formik.setFieldValue('clientOrProspectId', res.clientOrProspectId);
        formik.setFieldValue('clientOrProspectType', res.clientOrProspectType);
        formik.setFieldValue('premiumAmount', res.premiumAmount);
        formik.setFieldValue('fundAmount', res.fundAmount);
        formik.setFieldValue('depositAccountNo', res.depositAccountNo);
        formik.setFieldValue('depositBank', res.depositBank);
        if (res.invoiceNo) {
          formik.setFieldValue('invoiceNo', res.invoiceNo);
        }
        res.transactionDetails.forEach(el => {
          //cheque
          if (el.transactionMode === 'TM686778') {
            el.chequeDetails['chequeDateVal'] = new Date(el.chequeDetails.chequeDate);
          }
          // MPESA
          if (el.transactionMode === 'TM653068') {
            el.mpesaDetail['mpesaDateVal'] = new Date(el.mpesaDetail.mpsaDate);
          }
          //EFT
          if (el.transactionMode === 'TM773795') {
            el.eftDetail['eftDateVal'] = new Date(el.eftDetail.eftDate);
          }
          //DD
          if (el.transactionMode === 'TM227403') {
            el.demandDraftDetail['draftDateVal'] = new Date(el.demandDraftDetail.demandDraftDate);
          }
          // credit card
          if (el.transactionMode === 'TM838619') {
            el.creditCardDetail['cardTransactionDateVal'] = new Date(el.creditCardDetail.transactionDate);
            el.creditCardDetail['cardExpireVal'] = new Date(el.creditCardDetail.expireDate);
          }
          //debit
          if (el.transactionMode === 'TM309835') {
            el.debitCardDetail['cardTransactionDateVal'] = new Date(el.debitCardDetail.transactionDate);
            el.debitCardDetail['cardExpireVal'] = new Date(el.debitCardDetail.expireDate);
          }
        });

        setSelectedDate(new Date(res.receiptDate));
        setTransactionList(res.transactionDetails);
        setRevertedMessage(res.reverted);
        if (res.clientOrProspectType === 'Client') {
          clientservice.getClientDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.clientBasicDetails.displayName,
              clientEmail: cdata.clientBasicDetails.contactNos[0].contactNo,
              clientMobile: cdata.clientBasicDetails.emails[0].emailId,
              clientId: cdata.id,
              clientType: 'Client',
            });
            addr$.subscribe(result => {
              if (result && result.length !== 0) {
                result.forEach((prop, i) => {
                  prop.addressConfigurationFieldMappings.forEach((field, j) => {
                    // let fname = "field"+i+j;
                    field['value'] = '';
                    if (field.sourceId !== null && field.sourceId !== '') {
                      field['sourceList'] = [];
                    }
                    if (field.type === 'dropdown' && field.sourceId === null) {
                      if (field.addressConfigurationFieldCustomValueMappings.length !== 0) {
                        field['sourceList'] = field.addressConfigurationFieldCustomValueMappings;
                      }
                      // if(field.addressConfigurationFieldCustomValueMappings.length === 0 || field.addressConfigurationFieldCustomValueMappings === null){
                      //   field['sourceList'] = [];
                      // }
                    }
                  });
                });

                setAddressConfig(result);

                result.forEach((prop, i) => {
                  prop.addressConfigurationFieldMappings.map((field, j) => {
                    //   frmObj[field.fieldName] = field.defaultValue;
                    if (field.dependsOn == '' && field.type == 'dropdown' && field.modifyApiURL) {
                      addressservice.getSourceList(field.modifyApiURL).subscribe(res => {
                        // field.sourceList =res.content;
                        const list = [...result];
                        result[i].addressConfigurationFieldMappings[j].sourceList = res.content;
                        // frmLst[field.fieldName] = res.content;
                        populateDynamicAddress(cdata, result);
                      });
                    }
                  });
                });
                // setAddressConfig(result);

                // populateDynamicAddress(cdata,result)
              }
            });
          });
        }

        if (res.clientOrProspectType === 'Prospect') {
          prospectservice.getProspectDetails(res.clientOrProspectId).subscribe(cdata => {
            setClientData({
              clientName: cdata.displayName,
              clientEmail: cdata.emailId,
              clientMobile: cdata.mobileNo,
              clientId: cdata.id,
              clientType: 'Prospect',
            });
          });
        }
      });
    }
  };
  const handleClose = () => {
    history.push(`/receipts?mode=viewList`);
    // window.location.reload();
  };

  const handleSnackClose = (event, reason) => {
    setOpenSnack(false);
  };

  const handleSnackClose2 = (event, reason) => {
    setOpenSnack2(false);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Snackbar open={openSnack} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="error">
              Amount mismatch.The total transaction amount and summation of fund Amount and premium amount must be equal
            </Alert>
          </Snackbar>
          <Snackbar open={openSnack2} autoHideDuration={6000} onClose={handleSnackClose2}>
            <Alert onClose={handleSnackClose2} severity="error">
              Amount mismatch.The sum of denominations not matching transaction Amount
            </Alert>
          </Snackbar>
          <Grid container alignItems="flex-end" spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} sm={6} md={4}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                {/* <Grid container justify="space-around"> */}
                <KeyboardDatePicker
                  views={['year', 'month', 'date']}
                  variant="inline"
                  format="dd/MM/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  label="Invoice Date"
                  value={selectedDate}
                  disabled={query2.get('mode') === 'view' ? true : false}
                  InputProps={{
                    classes: {
                      root: classes.inputRoot,
                      disabled: classes.disabled,
                    },
                  }}
                  onChange={handleInvoiceDate}
                  KeyboardButtonProps={{
                    'aria-label': 'change ing date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12} sm={6} md={5}>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="receiptType"
                  value={formik.values.receiptType}
                  onChange={formik.handleChange}
                  defaultValue="top">
                  <FormControlLabel
                    value="receiptWithInvoice"
                    control={<Radio color="primary" />}
                    label="Receipt from invoice"
                    labelPlacement="end"
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                  <FormControlLabel
                    value="receiptWithoutInvoice"
                    control={<Radio color="primary" />}
                    label="Receipt without invoice"
                    labelPlacement="end"
                    disabled={query2.get('mode') === 'view' ? true : false}
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={3} container justifyContent="flex-end" alignItems="flex-end">
              {query2.get('mode') !== 'view' && (
                <Button variant="contained" color="secondary" onClick={handleopenClientModal}>
                  Search Client
                </Button>
              )}

              {query2.get('mode') === 'view' && revertedMessage && (
                <span style={{ color: 'red', fontWeight: 'bold' }}>REVERTED</span>
              )}

              <ReceiptClientModal
                openClientModal={openClientModal}
                handleCloseClientModal={handleCloseClientModal}
                handleSubmitClientModal={handleSubmitClientModal}
              />
            </Grid>
          </Grid>
          {formik.values.receiptType === 'receiptWithInvoice' && (
            <Grid container spacing={3} style={{ marginBottom: '20px' }} alignItems="flex-end">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl className={classes.formControl}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="invoiceNo"
                    label="Invoice Number"
                    value={formik.values.invoiceNo}
                    onChange={formik.handleChange}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={8} container justifyContent="flex-end">
                {query2.get('mode') !== 'view' ? (
                  <Button variant="contained" color="secondary" onClick={fetchInvoiceDetails}>
                    Search Invoice
                  </Button>
                ) : null}
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Accordion expanded={expandClientDetails} elevation="none">
            <AccordionSummary
              className={classes.AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={e => setExpandClientDetails(!expandClientDetails)}>
              <Typography className={classes.heading}>Client Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientName"
                      readonly={true}
                      label="Name"
                      value={clientData.clientName}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientEmail"
                      readonly={true}
                      label="Email"
                      value={clientData.clientEmail}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="clientMobile"
                      label="Contact"
                      readonly={true}
                      value={clientData.clientMobile}
                    />
                  </FormControl>
                </Grid>

                {addressConfig && addressConfig.length !== 0 && (
                  <Grid item xs={12} style={{ marginBottom: '20px' }} container>
                    {addressConfig.map((prop, i) => {
                      return prop.addressConfigurationFieldMappings.length !== 1 ? (
                        <Grid item xs={12} sm={6} md={4} key={i} container>
                          <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                              {prop.levelName}
                            </InputLabel>
                            {prop.iButtonRequired === 'true' && (
                              <Tooltip title={prop.iButtonMessage} placement="top">
                                <InfoOutlinedIcon style={{ fontSize: 'medium' }} />
                              </Tooltip>
                            )}
                          </div>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <Grid item xs={12} sm={6} md={4}>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      id="demo-simple-select"
                                      required={field.required === 'true' ? true : false}
                                      style={{ marginRight: '8px' }}
                                      value={field.value}
                                      readOnly>
                                      {field.sourceList.map(ele => {
                                        return (
                                          <MenuItem value={ele.code} key={ele.code}>
                                            {ele.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </Select>
                                  </FormControl>
                                )}
                                {field.type === 'textbox' && !field.readOnly && (
                                  <TextField
                                    id="standard-basic"
                                    name={field.fieldName}
                                    type={field.dataType === 'numeric' ? 'number' : 'text'}
                                    style={{ marginTop: '8px' }}
                                    value={field.value}
                                    readOnly
                                  />
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <TextField
                                    required={field.required === 'true' ? true : false}
                                    id="standard-multiline-flexible"
                                    multiline
                                    name={field.fieldName}
                                    rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                    value={field.value}
                                    readOnly
                                  />
                                )}
                                {field.readOnly && (
                                  <TextField
                                    id="standard-basic"
                                    name={field.fieldName}
                                    // value={field.defaultValue}
                                    defaultValue={field.defaultValue}
                                    readonly
                                    style={{ marginTop: '10px', marginRight: '8px', width: '15%' }}
                                    size="small"
                                  />
                                )}
                              </Grid>
                            );
                          })}
                        </Grid>
                      ) : (
                        <Grid item xs={12} sm={6} md={4} key={i + 50}>
                          {prop.addressConfigurationFieldMappings.map((field, j) => {
                            return (
                              <div key={j + 2}>
                                {field.type === 'dropdown' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                      {prop.levelName}
                                    </InputLabel>
                                    <Select
                                      labelId="demo-simple-select-label"
                                      name={field.fieldName}
                                      required={field.required === 'true' ? true : false}
                                      id="demo-simple-select"
                                      value={field.value}
                                      readOnly>
                                      {field.customValuePresent === 'CUSTOM' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.id} key={ele.id}>
                                              {ele.value}
                                            </MenuItem>
                                          );
                                        })}

                                      {field.customValuePresent === 'DYNAMIC' &&
                                        field.sourceList.map(ele => {
                                          return (
                                            <MenuItem value={ele.code} key={ele.code}>
                                              {ele.name}
                                            </MenuItem>
                                          );
                                        })}
                                    </Select>
                                  </FormControl>
                                )}

                                {field.type === 'textbox' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      required={field.required === 'true' ? true : false}
                                      id="standard-basic"
                                      name={field.fieldName}
                                      type={field.dataType === 'numeric' ? 'number' : 'text'}
                                      value={field.value}
                                      // value={formObj[field.fieldName] ? formObj[field.fieldName] : ""}
                                      label={prop.levelName}
                                      readOnly
                                    />
                                  </FormControl>
                                )}

                                {field.type === 'textarea' && !field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-multiline-flexible"
                                      // required={field.required === 'true' ? true : false}
                                      multiline
                                      name={field.fieldName}
                                      rowsMax={field.lengthValidation ? Number(prop.size) : 5}
                                      value={field.value}
                                      // value={values.addressData[field.fieldName] ? values.addressData[field.fieldName] : ""}
                                      readOnly
                                      label={
                                        field.required === 'true'
                                          ? <span>{prop.levelName} <Asterisk/></span>
                                          : prop.levelName
                                      }
                                    />
                                  </FormControl>
                                )}
                                {field.readOnly && (
                                  <FormControl className={classes.formControl}>
                                    <TextField
                                      id="standard-basic"
                                      name={field.fieldName}
                                      value={field.defaultValue}
                                      label={prop.levelName}
                                      defaultValue={field.defaultValue}
                                      readOnly
                                    />
                                  </FormControl>
                                )}
                                {prop.iButtonRequired === 'true' && (
                                  <Tooltip title={prop.iButtonMessage} placement="top">
                                    <InfoOutlinedIcon
                                      style={{ fontSize: 'medium', marginTop: '23px', marginLeft: '-23px' }}
                                    />
                                  </Tooltip>
                                )}
                              </div>
                            );
                          })}
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion expanded={expandTransactionDetails} elevation="none">
            <AccordionSummary
              className={classes.AccordionSummary}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={e => setExapandTransactionDetails(!expandTransactionDetails)}>
              <Typography className={classes.heading}>Transaction Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                  <span style={{ color: '#4472C4' }}>Deposit Details</span>
                </Grid>
                {/* <Grid item xs={12}>
                                    <Divider />
                                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                      Deposit Bank
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      name="depositBank"
                      id="demo-simple-select"
                      disabled={query2.get('mode') === 'view' ? true : false}
                      value={formik.values.depositBank}
                      onChange={formik.handleChange}
                      error={formik.touched.depositBank && Boolean(formik.errors.depositBank)}
                      helperText={formik.touched.depositBank && formik.errors.depositBank}>
                      {bankList.map(ele => {
                        return (
                          <MenuItem key={ele.id} value={ele.id}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="standard-basic"
                      name="depositAccountNo"
                      value={formik.values.depositAccountNo}
                      onChange={formik.handleChange}
                      disabled={query2.get('mode') === 'view' ? true : false}
                      error={formik.touched.depositAccountNo && Boolean(formik.errors.depositAccountNo)}
                      helperText={formik.touched.depositAccountNo && formik.errors.depositAccountNo}
                      label="Deposit account no"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <span style={{ color: '#4472C4' }}>Receipt Details</span>
                </Grid>
                {/* <Grid item xs={12}>
                                    <Divider />
                                </Grid> */}
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="standard-basic"
                      name="premiumAmount"
                      type="number"
                      disabled={
                        query2.get('mode') === 'view' || formik.values.receiptType === 'receiptWithInvoice' ? true : false
                      }
                      value={formik.values.premiumAmount}
                      onChange={formik.handleChange}
                      error={formik.touched.premiumAmount && Boolean(formik.errors.premiumAmount)}
                      helperText={formik.touched.premiumAmount && formik.errors.premiumAmount}
                      label="Premium Amount"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <FormControl className={classes.formControl}>
                    <TextField
                      id="standard-basic"
                      name="fundAmount"
                      type="number"
                      disabled={query2.get('mode') === 'view' ? true : false}
                      value={formik.values.fundAmount}
                      onChange={formik.handleChange}
                      error={formik.touched.fundAmount && Boolean(formik.errors.fundAmount)}
                      helperText={formik.touched.fundAmount && formik.errors.fundAmount}
                      label="Fund Amount"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <span style={{ color: '#4472C4' }}>Transaction Details</span>
                </Grid>
                {/* <Grid item xs={12}>
                                    <Divider />
                                </Grid> */}
                {transactionList.map((x, i) => {
                  return (
                    <Grid container spacing={12} key={i} style={{ marginBottom: '20px', marginTop: '30px' }}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                            Transaction Mode
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="transactionMode"
                            value={x.transactionMode}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={e => handleTransactionModeChange(e, i)}
                            error={x.transactionMode === null || x.transactionMode === ''}
                            helperText={x.transactionMode === '' ? 'required field' : ' '}>
                            {transactionModeList.map(ele => {
                              return (
                                <MenuItem key={ele.code} disabled={getdisabledStatus(ele.code)} value={ele.code}>
                                  {ele.name}
                                </MenuItem>
                              );
                            })}
                            {/* <MenuItem key="MPESA" disabled={getdisabledStatus("MPESA")} value="MPESA">MPESA</MenuItem>
                                                        <MenuItem key="DD" disabled={getdisabledStatus("DD")} value="DD">Demand Draft</MenuItem>
                                                        <MenuItem key="Card" disabled={getdisabledStatus("Card")} value="Card">Card</MenuItem>
                                                        <MenuItem key="EFT" disabled={getdisabledStatus("EFT")} value="EFT">EFT</MenuItem> */}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            id="standard-basic"
                            name="transactionAmount"
                            type="number"
                            value={x.transactionAmount}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={e => handleInputChangeTransaction(e, i)}
                            label="Transaction Amount"
                            error={x.transactionAmount === null || x.transactionAmount === ''}
                            helperText={x.transactionAmount === '' ? 'required field' : ' '}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                            Transaction Currency
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name="transactionCurrency"
                            value={x.transactionCurrency}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={e => handleInputChangeTransaction(e, i)}
                            error={x.transactionCurrency === null || x.transactionCurrency === ''}
                            helperText={x.transactionCurrency === '' ? 'required field' : ' '}>
                            {currencyList.map(ele => {
                              return (
                                <MenuItem key={ele.code} value={ele.code}>
                                  {ele.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            id="standard-basic"
                            name="exchangeRate"
                            type="number"
                            value={x.exchangeRate}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={e => handleInputChangeTransaction(e, i)}
                            error={x.exchangeRate === null}
                            helperText={x.exchangeRate === '' ? 'required field' : ' '}
                            label="Exchange Rate"
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl className={classes.formControl}>
                          <TextField
                            id="standard-basic"
                            name="exchangeAmount"
                            type="number"
                            value={x.exchangeAmount}
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onChange={e => handleInputChangeTransaction(e, i)}
                            error={x.exchangeAmount === null}
                            helperText={x.exchangeAmount === '' ? 'required field' : ' '}
                            label="Exchange Amount"
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={2} style={{ display: 'flex', alignItems: 'center' }}>
                        {transactionList.length !== 1 && (
                          <Button
                            className="mr10"
                            onClick={() => handleRemoveClickTransaction(i)}
                            variant="contained"
                            color="secondary"
                            disabled={query2.get('mode') === 'view' ? true : false}
                            style={{ marginRight: '5px' }}>
                            <DeleteIcon style={{color:"#dc3545"}} />
                          </Button>
                        )}
                        {transactionList.length - 1 === i && (
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={query2.get('mode') === 'view' ? true : false}
                            onClick={handleAddClickTransaction}>
                            <AddIcon />
                          </Button>
                        )}
                      </Grid>
                      {/* cash */}
                      {x.transactionModeName?.toLowerCase() === 'cash' && (
                        <Grid item xs={12} style={{ marginTop: '10px' }}>
                          <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Denominations</TableCell>
                                  <TableCell>No of Notes/Coins</TableCell>
                                  <TableCell align="right">Total Value</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {x.denominations.map((y, j) => (
                                  <TableRow key={j}>
                                    <TableCell>
                                      <span>{y.currencyValue} X</span>
                                    </TableCell>
                                    <TableCell>
                                      <TextField
                                        id="standard-basic"
                                        name="noOfCurrency"
                                        type="number"
                                        min={0}
                                        disabled={query2.get('mode') === 'view' ? true : false}
                                        value={y.noOfCurrency}
                                        onChange={e => handleDenominationAmount(e, i, j)}
                                        // label="No. of Notes/Coins"
                                      />
                                    </TableCell>
                                    <TableCell align="right">
                                      <span>{y.noOfCurrency * y.currencyValue}</span>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Grid>
                      )}

                      {/* cheque */}

                      {x.transactionModeName?.toLowerCase() === 'cheque' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="chequeNo"
                                value={x.chequeDetails.chequeNo}
                                onChange={e => handleCheque(e, i)}
                                label="Cheque No"
                                disabled={query2.get('mode') === 'view' ? true : false}
                                error={x.chequeDetails.chequeNo === null || x.chequeDetails.chequeNo === ''}
                                helperText={x.chequeDetails.chequeNo === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Bank
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="bankName"
                                id="demo-simple-select"
                                disabled={query2.get('mode') === 'view' ? true : false}
                                value={x.chequeDetails.bankName}
                                onChange={e => handleCheque(e, i)}
                                error={x.chequeDetails.bankName === null || x.chequeDetails.bankName === ''}
                                helperText={x.chequeDetails.bankName === '' ? 'required field' : ' '}>
                                {bankList.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem key="1111" value="other">
                                  Other
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {x.chequeDetails.bankName === 'other' && (
                            <Grid item xs={12} sm={6} md={3}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  id="standard-basic"
                                  name="otherBankName"
                                  value={x.chequeDetails.otherBankName}
                                  onChange={e => handleCheque(e, i)}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  label="Other Bank"
                                  error={x.chequeDetails.otherBankName === null || x.chequeDetails.otherBankName === ''}
                                  helperText={x.chequeDetails.otherBankName === '' ? 'required field' : ' '}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="branch"
                                value={x.chequeDetails.branch}
                                onChange={e => handleCheque(e, i)}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                label="Branch"
                                error={x.chequeDetails.branch === null || x.chequeDetails.branch === ''}
                                helperText={x.chequeDetails.branch === '' ? 'required field' : ' '}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={3}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Cheque Date"
                                  name="chequeDateVal"
                                  value={x.chequeDetails.chequeDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleChequeDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* MPESA */}
                      {x.transactionModeName?.toLowerCase() === 'mpesa' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="mpsaNo"
                                value={x.mpesaDetail.mpsaNo}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleMPESA(e, i)}
                                label="MPESA No"
                                error={x.mpesaDetail.mpsaNo === null || x.mpesaDetail.mpsaNo === ''}
                                helperText={x.mpesaDetail.mpsaNo === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="MPESA Date"
                                  name="mpesaDateVal"
                                  value={x.mpesaDetail.mpesaDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleMPESADate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* EFT */}
                      {x.transactionModeName?.toLowerCase() === 'eft' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="eftNo"
                                value={x.eftDetail.eftNo}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleEFT(e, i)}
                                label="EFT No"
                                error={x.eftDetail.eftNo === null || x.eftDetail.eftNo === ''}
                                helperText={x.eftDetail.eftNo === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Bank
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="bankName"
                                id="demo-simple-select"
                                disabled={query2.get('mode') === 'view' ? true : false}
                                value={x.eftDetail.bankName}
                                onChange={e => handleEFT(e, i)}
                                error={x.eftDetail.bankName === null || x.eftDetail.bankName === ''}
                                helperText={x.eftDetail.bankName === '' ? 'required field' : ' '}>
                                {bankList.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem key="1111" value="other">
                                  Other
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {x.eftDetail.bankName === 'other' && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  id="standard-basic"
                                  name="otherBankName"
                                  value={x.eftDetail.otherBankName}
                                  onChange={e => handleEFT(e, i)}
                                  label="Other Bank"
                                  error={x.eftDetail.otherBankName === null || x.eftDetail.otherBankName === ''}
                                  helperText={x.eftDetail.otherBankName === '' ? 'required field' : ' '}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="EFT Date"
                                  name="eftDateVal"
                                  value={x.eftDetail.eftDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleEFTDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* DD */}
                      {x.transactionModeName?.toLowerCase() === 'dd' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="demandDraftNo"
                                value={x.demandDraftDetail.demandDraftNo}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleDraft(e, i)}
                                label="Demand draft No"
                                error={
                                  x.demandDraftDetail.demandDraftNo === null || x.demandDraftDetail.demandDraftNo === ''
                                }
                                helperText={x.demandDraftDetail.demandDraftNo === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Bank
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="bankName"
                                id="demo-simple-select"
                                disabled={query2.get('mode') === 'view' ? true : false}
                                value={x.demandDraftDetail.bankName}
                                onChange={e => handleDraft(e, i)}
                                error={x.demandDraftDetail.bankName === null || x.demandDraftDetail.bankName === ''}
                                helperText={x.demandDraftDetail.bankName === '' ? 'required field' : ' '}>
                                {bankList.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem key="1111" value="other">
                                  Other
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {x.demandDraftDetail.bankName === 'other' && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  id="standard-basic"
                                  name="otherBankName"
                                  value={x.demandDraftDetail.otherBankName}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  onChange={e => handleDraft(e, i)}
                                  label="Other Bank"
                                  error={
                                    x.demandDraftDetail.otherBankName === null || x.demandDraftDetail.otherBankName === ''
                                  }
                                  helperText={x.demandDraftDetail.otherBankName === '' ? 'required field' : ' '}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="branch"
                                value={x.demandDraftDetail.branch}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleDraft(e, i)}
                                label="Branch"
                                error={x.demandDraftDetail.branch === null || x.demandDraftDetail.branch === ''}
                                helperText={x.demandDraftDetail.branch === '' ? 'required field' : ' '}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Draft Date"
                                  name="draftDateVal"
                                  value={x.demandDraftDetail.draftDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleDraftDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* CREDIT */}
                      {x.transactionModeName?.toLowerCase() === 'credit card' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="cardNumber"
                                value={x.creditCardDetail.cardNumber}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleCardDetails(e, i)}
                                label="Credit No"
                                error={x.creditCardDetail.cardNumber === null || x.creditCardDetail.cardNumber === ''}
                                helperText={x.creditCardDetail.cardNumber === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Bank
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="bankName"
                                id="demo-simple-select"
                                // disabled={query2.get("mode") === 'view' ? true : false}
                                value={x.creditCardDetail.bankName}
                                onChange={e => handleCardDetails(e, i)}
                                error={x.creditCardDetail.bankName === null || x.creditCardDetail.bankName === ''}
                                helperText={x.creditCardDetail.bankName === '' ? 'required field' : ' '}>
                                {bankList.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem key="1111" value="other">
                                  Other
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {x.creditCardDetail.bankName === 'other' && (
                            <Grid item xs={12} sm={6} md={4}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  id="standard-basic"
                                  name="otherBankName"
                                  value={x.creditCardDetail.otherBankName}
                                  onChange={e => handleCardDetails(e, i)}
                                  label="Other Bank"
                                  error={
                                    x.creditCardDetail.otherBankName === null || x.creditCardDetail.otherBankName === ''
                                  }
                                  helperText={x.creditCardDetail.otherBankName === '' ? 'required field' : ' '}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="branch"
                                value={x.creditCardDetail.branch}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleCardDetails(e, i)}
                                label="Branch"
                                error={x.creditCardDetail.branch === null || x.creditCardDetail.branch === ''}
                                helperText={x.creditCardDetail.branch === '' ? 'required field' : ' '}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Card Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="cardType"
                                id="demo-simple-select"
                                value={x.creditCardDetail.cardType}
                                onChange={e => handleCardDetails(e, i)}
                                error={x.creditCardDetail.cardType === null || x.creditCardDetail.cardType === ''}
                                helperText={x.creditCardDetail.cardType === '' ? 'required field' : ' '}>
                                <MenuItem key="1234" value="master">
                                  Master Card
                                </MenuItem>
                                <MenuItem key="5678" value="visa">
                                  VISA
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                views={["year", "month", "date"]}
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Transaction Date"
                                                                name="cardTransactionDateVal"
                                                                value={x.creditCardDetail.cardTransactionDateVal}
                                                                disabled={query2.get("mode") === 'view' ? true : false}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.inputRoot,
                                                                        disabled: classes.disabled
                                                                    }
                                                                }}
                                                                onChange={(e) => handleTransactionDate(e, i)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change ing date',
                                                                }}
                                                            /> </MuiPickersUtilsProvider>
                                                            </Grid> */}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Expire Date"
                                  name="cardExpireDateVal"
                                  value={x.creditCardDetail.cardExpireDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleExpireDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Transaction Date"
                                  name="cardTransactionDateVal"
                                  value={x.creditCardDetail.cardTransactionDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleTransactionDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}
                      {/* DEBIT */}

                      {x.transactionModeName?.toLowerCase() === 'debit card' && (
                        <Grid container spacing={12} style={{ marginBottom: '10px', marginTop: '30px' }}>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="cardNumber"
                                value={x.debitCardDetail.cardNumber}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleDebitCardDetails(e, i)}
                                label="Debit card No"
                                error={x.debitCardDetail.cardNumber === null || x.debitCardDetail.cardNumber === ''}
                                helperText={x.debitCardDetail.cardNumber === '' ? 'required field' : ' '}
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Bank
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="bankName"
                                id="demo-simple-select"
                                disabled={query2.get('mode') === 'view' ? true : false}
                                value={x.debitCardDetail.bankName}
                                onChange={e => handleDebitCardDetails(e, i)}
                                error={x.debitCardDetail.bankName === null || x.debitCardDetail.bankName === ''}
                                helperText={x.debitCardDetail.bankName === '' ? 'required field' : ' '}>
                                {bankList.map(ele => {
                                  return (
                                    <MenuItem key={ele.id} value={ele.id}>
                                      {ele.name}
                                    </MenuItem>
                                  );
                                })}
                                <MenuItem key="1111" value="other">
                                  Other
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>
                          {x.debitCardDetail.bankName === 'other' && (
                            <Grid xs={12} sm={6} md={4}>
                              <FormControl className={classes.formControl}>
                                <TextField
                                  id="standard-basic"
                                  name="otherBankName"
                                  value={x.debitCardDetail.otherBankName}
                                  onChange={e => handleDebitCardDetails(e, i)}
                                  label="Other Bank"
                                  error={x.debitCardDetail.otherBankName === null || x.debitCardDetail.otherBankName === ''}
                                  helperText={x.debitCardDetail.otherBankName === '' ? 'required field' : ' '}
                                />
                              </FormControl>
                            </Grid>
                          )}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <TextField
                                id="standard-basic"
                                name="branch"
                                value={x.debitCardDetail.branch}
                                disabled={query2.get('mode') === 'view' ? true : false}
                                onChange={e => handleDebitCardDetails(e, i)}
                                label="Branch"
                                error={x.debitCardDetail.branch === null || x.debitCardDetail.branch === ''}
                                helperText={x.debitCardDetail.branch === '' ? 'required field' : ' '}
                              />
                            </FormControl>
                          </Grid>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                                Card Type
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                name="cardType"
                                id="demo-simple-select"
                                value={x.debitCardDetail.cardType}
                                onChange={e => handleDebitCardDetails(e, i)}
                                error={x.debitCardDetail.cardType === null || x.debitCardDetail.cardType === ''}
                                helperText={x.debitCardDetail.cardType === '' ? 'required field' : ' '}>
                                <MenuItem key="1234" value="master">
                                  Master Card
                                </MenuItem>
                                <MenuItem key="5678" value="visa">
                                  VISA
                                </MenuItem>
                              </Select>
                            </FormControl>
                          </Grid>

                          {/* <Grid item xs={12}>
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                views={["year", "month", "date"]}
                                                                variant="inline"
                                                                format="dd/MM/yyyy"
                                                                margin="normal"
                                                                id="date-picker-inline"
                                                                label="Transaction Date"
                                                                name="cardTransactionDateVal"
                                                                value={x.creditCardDetail.cardTransactionDateVal}
                                                                disabled={query2.get("mode") === 'view' ? true : false}
                                                                InputProps={{
                                                                    classes: {
                                                                        root: classes.inputRoot,
                                                                        disabled: classes.disabled
                                                                    }
                                                                }}
                                                                onChange={(e) => handleTransactionDate(e, i)}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change ing date',
                                                                }}
                                                            /> </MuiPickersUtilsProvider>
                                                            </Grid> */}
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Expire Date"
                                  name="cardExpireDateVal"
                                  value={x.debitCardDetail.cardExpireDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleDebitExpireDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl className={classes.formControl}>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                {/* <Grid container justify="space-around"> */}
                                <KeyboardDatePicker
                                  views={['year', 'month', 'date']}
                                  variant="inline"
                                  format="dd/MM/yyyy"
                                  margin="normal"
                                  id="date-picker-inline"
                                  label="Transaction Date"
                                  name="cardTransactionDateVal"
                                  value={x.debitCardDetail.cardTransactionDateVal}
                                  disabled={query2.get('mode') === 'view' ? true : false}
                                  InputProps={{
                                    classes: {
                                      root: classes.inputRoot,
                                      disabled: classes.disabled,
                                    },
                                  }}
                                  onChange={e => handleDebitTransactionDate(e, i)}
                                  KeyboardButtonProps={{
                                    'aria-label': 'change ing date',
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                        </Grid>
                      )}

                      {/* {query2.get("mode") !== 'view' && x.transactionMode === 'TM686778' &&
                                                x.denominations.map((y, j) => {
                                                    return <Grid
                                                        container
                                                        spacing={12}
                                                        key={j}
                                                        style={{ marginBottom: "10px", marginTop: "30px" }}
                                                    >
                                                        <Grid item xs={2}>
                                                            <span>
                                                                {y.val} X
                                                   </span>
                                                        </Grid>
                                                        <Grid item xs={2}>
                                                            <TextField
                                                                id="standard-basic"
                                                                name="noOfCurrency"
                                                                type="number"
                                                                min={0}
                                                                value={y.noOfCurrency}
                                                                onChange={(e) => handleDenominationAmount(e, i, j)}
                                                                label="No. of Notes/Coins"

                                                            /></Grid>
                                                        <Grid item xs={2}>
                                                            <span>{y.noOfCurrency * y.val}</span>
                                                        </Grid>

                                                    </Grid>
                                                })
                                                : null} */}
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {query2.get('mode') !== 'view' ? (
                <Button variant="contained" color="secondary" style={{ marginRight: '5px' }} type="submit">
                  Save
                </Button>
              ) : null}
              <Button variant="primary" className={`${query2.get('mode') === 'view' ? "p-button" : "p-button-text"}`}onClick={handleClose}>
                {query2.get('mode') === 'view' ? 'Ok' : 'Cancel'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </form>
  );
}
