/* import DateFnsUtils from "@date-io/date-fns"; */
// import Button from "@material-ui/core/Button";
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
/* import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers"; */
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  GenderTypeService,
  ServiceGroupingsService,
  ServiceGroupService,
  ServiceTypeService,
} from '../../remote-api/api/master-services';
import {
  defaultPageRequestServiceGrouping,
  defaultPageRequestServices,
} from '../../remote-api/models/page.request.service.grouping';
import { FettleAutocomplete } from '../../shared-components';
import { Button } from 'primereact/button';

const serviceTypeService = new ServiceTypeService();
const genderTypeService = new GenderTypeService();
const serviceGroupService = new ServiceGroupService();
const serviceGroupingsService = new ServiceGroupingsService();

const useStyles = theme => ({
  root: {
    flexGrow: 1,
    minHeight: 100,
    padding: 30,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 276,
  },
  serviceAutoComplete: {
    /* width: 500, */
    '& .MuiInputBase-formControl': {
      maxHeight: 500,
      overflowX: 'hidden',
      overflowY: 'auto',
    },
  },
  actionBlock: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  addGroupBtnSection: {
    display: 'flex',
    alignItems: 'center',
  },
});

class ServiceGroupingFormComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceGroupingForm: {
        serviceTypeId: '',
        groupId: '',
        group: {},
        serviceIds: [],
        startTime: new Date(),
        endTime: null,
      },
      addGroupForm: {
        name: '',
        code: '',
        genderId: '',
        isParent: false,
        parentId: '',
      },
      serviceTypes: [],
      genders: [],
      openDialog: false,
      serviceTypeChangeDetect: false,
      groupChangeDetect: false,
      parentGroupChangeDetect: false,
    };
    this.serviceGroupPage = 0;
    this.pageSize = 10;
    this.serviceGroupSearchText = '';
  }

  componentDidMount() {
    this.getServiceTypes();
    this.getserviceGroupingsById();
  }

  getServiceTypes = () => {
    let serviceTypeService$ = serviceTypeService.getServiceTypes();
    serviceTypeService$.subscribe(response => {
      this.setState({
        ...this.state,
        serviceTypes: response.content,
      });
    });
  };

  getserviceGroupingsById = () => {
    const ID = this.props.match.params.id;
    if (ID) {
      serviceGroupingsService.getServiceGroupingsById(Number(ID)).subscribe(res => {
        this.setState({
          ...this.state,
          serviceGroupingForm: {
            ...this.state.serviceGroupingForm,
            ...res /* 
                        groupId: "868035070228639744",
                        serviceIds: ["868181445398441984", "868225630591725568"], */,
            startTime: new Date(res.startDate),
          },
          serviceTypeChangeDetect: res.serviceTypeId,
          groupChangeDetect: res.groupId,
        });
      });
    }
  };

  groupDataSourceCallback$ = (params = {}, action = '', pageRequest = defaultPageRequestServiceGrouping) => {
    let reqParam = { ...pageRequest, ...params };
    if (action.searchText) {
      reqParam = {
        ...reqParam,
        groupCode: action.searchText,
        groupName: action.searchText,
      };
    }
    const serviceTypeId = this.state.serviceGroupingForm.serviceTypeId;
    return serviceTypeService.getServiceGroupes(serviceTypeId, reqParam);
  };

  servicesDataSourceCallback$ = (params = {}, action = '', pageRequest = defaultPageRequestServices) => {
    let reqParam = { ...pageRequest, ...params };
    if (action.searchText) {
      reqParam = {
        ...reqParam,
        nameAlias: action.searchText,
        icdCode: action.searchText,
        name: action.searchText,
      };
    }
    const serviceTypeId = this.state.serviceGroupingForm.serviceTypeId;
    const groupId = this.state.serviceGroupingForm.groupId;
    return serviceTypeService.getServices(serviceTypeId, groupId, reqParam);
  };

  parentGroupDataSourceCallback$ = (params = {}, action = '', pageRequest = defaultPageRequestServiceGrouping) => {
    let reqParam = {
      ...pageRequest,
      ...params,
      nonGroupedServiceGroup: false,
      parentEligibleServiceGroupIrrespectiveGruping: true,
    };
    if (action.searchText) {
      reqParam = {
        ...reqParam,
        groupCode: action.searchText,
        groupName: action.searchText,
      };
    }
    const serviceTypeId = this.state.serviceGroupingForm.serviceTypeId;
    return serviceTypeService.getServiceGroupes(serviceTypeId, reqParam);
  };

  handleChange = e => {
    const { name, value } = e.target;

    this.setState({
      ...this.state,
      serviceGroupingForm: {
        ...this.state.serviceGroupingForm,
        [name]: value,
        ...(name === 'serviceTypeId' && { groupId: '', serviceIds: [] }),
      },
      ...(name === 'serviceTypeId' && { serviceTypeChangeDetect: value }),
    });
  };

  handleAddGroupChange = e => {
    const { name, value, checked } = e.target;

    if (name === 'isParent') {
      this.setState({
        ...this.state,
        addGroupForm: {
          ...this.state.addGroupForm,
          [name]: checked,
        },
        parentGroupChangeDetect: this.state.serviceGroupingForm.serviceTypeId,
      });
    } else {
      this.setState({
        ...this.state,
        addGroupForm: {
          ...this.state.addGroupForm,
          [name]: value,
        },
      });
    }
  };

  handleServiceAutocompleteChange = (e, newValue, name) => {
    if (newValue && Array.isArray(newValue)) {
      this.setState({
        ...this.state,
        serviceGroupingForm: {
          ...this.state.serviceGroupingForm,
          [name]: newValue.map(o => o.id),
        },
      });
    } else {
      this.setState({
        ...this.state,
        serviceGroupingForm: {
          ...this.state.serviceGroupingForm,
          [name]: newValue ? newValue.id : '',
          group: newValue,
          ...(name === 'groupId' && { serviceIds: [] }),
        },
        groupChangeDetect: newValue ? newValue.id : '',
      });
    }
  };

  handleParentGroupAutoCompleteChange = (e, newValue, name) => {
    this.setState({
      ...this.state,
      addGroupForm: {
        ...this.state.addGroupForm,
        [name]: newValue ? newValue.id : '',
      },
    });
  };

  handleDateChange = (e, name) => {
    this.setState({
      ...this.state,
      serviceGroupingForm: {
        ...this.state.serviceGroupingForm,
        [name]: e,
      },
    });
  };

  addGroupModalToggle = status => e => {
    this.setState({
      ...this.state,
      openDialog: status,
    });
    if (status) {
      this.getGenders();
    }
  };

  getGenders = () => {
    genderTypeService.getGenders().subscribe(res => {
      this.setState({
        ...this.state,
        genders: res.content,
      });
    });
  };

  handleAddGroup = () => {
    const { name, code, parentId, genderId } = this.state.addGroupForm;
    const requestPayload = {
      code,
      serviceGroupCreationDTO: {
        name,
        serviceTypeId: this.state.serviceGroupingForm.serviceTypeId,
        parentId,
        genderId,
      },
    };

    serviceGroupService.saveGrouping(requestPayload).subscribe(res => {
      this.setState({
        ...this.state,
        serviceTypeChangeDetect: 'new group ' + Math.random(),
      });
    });
    this.handleClose();
  };

  saveServiceGroup = () => {
    const { startTime, serviceIds } = this.state.serviceGroupingForm;
    const requestPayload = {
      ...((!this.props.match.params.id && { serviceGroupActivationDTO: { serviceIds } }) || {
        serviceGroupTerminationDTO: { serviceIds },
      }),
    };

    // const step = this.props.match.params.id ? 2 : 1;

    serviceGroupService.updateGrouping(this.state.serviceGroupingForm.groupId, requestPayload, 1).subscribe(res => {
      this.handleClose();
    });
  };

  handleClose = () => {
    this.props.history.push('/masters/service-grouping');
  };

  render() {
    const { classes } = this.props;
    const {
      serviceGroupingForm,
      openDialog,
      addGroupForm,
      serviceTypes,
      serviceTypeChangeDetect,
      groupChangeDetect,
      parentGroupChangeDetect,
      genders,
    } = this.state;

    return (
      <div>
        <Typography variant="h6" gutterBottom>
          Service Grouping: {this.props.match.params.id ? 'Edit' : 'Add'}
        </Typography>
        <Paper elevation="none">
          <div className={classes.root}>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="select-service-type-label">Service Type</InputLabel>
                  <Select
                    name="serviceTypeId"
                    value={serviceGroupingForm.serviceTypeId}
                    onChange={this.handleChange}
                    displayEmpty
                    className={classes.selectEmpty}
                    disabled={this.props.match.params.id}
                    inputProps={{ 'aria-label': 'Without label' }}>
                    {serviceTypes.map(item => (
                      <MenuItem value={item.id}>{item.displayName}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                {(!this.props.match.params.id && (
                  <FettleAutocomplete
                    id="group-name"
                    name="groupId"
                    label="Group"
                    $dataSource={this.groupDataSourceCallback$}
                    value={serviceGroupingForm.group}
                    changeDetect={serviceTypeChangeDetect}
                    onChange={(e, newValue) => this.handleServiceAutocompleteChange(e, newValue, 'groupId')}
                  />
                )) || (
                  <React.Fragment>
                    <FormControl className={classes.formControl} style={{ marginTop: 15, marginBottom: 15 }}>
                      <TextField value={serviceGroupingForm.groupName || ''} label="Group" disabled />
                    </FormControl>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xs={3} className={classes.addGroupBtnSection}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.addGroupModalToggle(true)}
                  disabled={this.props.match.params.id}>
                  Add Group
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <FettleAutocomplete
                  id="services"
                  name="serviceIds"
                  label="Services"
                  $dataSource={this.servicesDataSourceCallback$}
                  multiple={true}
                  value={serviceGroupingForm.serviceIds ?? []}
                  changeDetect={groupChangeDetect}
                  onChange={(e, newValue) => this.handleServiceAutocompleteChange(e, newValue, 'serviceIds')}
                />
              </Grid>
            </Grid>

            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <Grid container spacing={1}>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <KeyboardDatePicker
                                            views={["year", "month", "date"]}
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-from"
                                            label="Date From"
                                            name="startTime"
                                            value={serviceGroupingForm.startTime}
                                            onChange={(e) => this.handleDateChange(e, 'startTime')}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl className={classes.formControl}>
                                        <KeyboardDatePicker
                                            disabled
                                            views={["year", "month", "date"]}
                                            variant="inline"
                                            format="MM/dd/yyyy"
                                            margin="normal"
                                            id="date-upto"
                                            label="Date Upto"
                                            name="endTime"
                                            value={serviceGroupingForm.endTime}
                                            onChange={(e) => this.handleDateChange(e, 'endTime')}
                                            KeyboardButtonProps={{
                                                "aria-label": "change date",
                                            }}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </MuiPickersUtilsProvider> */}

            <Grid container spacing={1}>
              <Grid item xs={12} className={classes.actionBlock}>
                <Button variant="contained" color="primary" onClick={this.saveServiceGroup}>
                  Save
                </Button>
                <Button
                  variant="contained"
                  className="p-button-text"
                  style={{ marginLeft: 15 }}
                  onClick={() => this.handleClose()}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </Paper>

        <Dialog
          fullWidth
          maxWidth="sm"
          open={openDialog}
          onClose={this.addGroupModalToggle(false)}
          aria-labelledby="max-width-dialog-title">
          <DialogTitle id="max-width-dialog-title">Add Group</DialogTitle>
          <DialogContent>
            <form className={classes.form} noValidate>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField name="name" label="Name" value={addGroupForm.name} onChange={this.handleAddGroupChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <TextField name="code" label="Code" value={addGroupForm.code} onChange={this.handleAddGroupChange} />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                      labelId="gender-label"
                      name="genderId"
                      value={addGroupForm.gender}
                      onChange={this.handleAddGroupChange}
                      MenuProps={{
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                      }}>
                      {genders.map(item => (
                        <MenuItem value={item.id}> {item.name} </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    className={classes.formControl}
                    control={
                      <Checkbox
                        checked={addGroupForm.isParent}
                        onChange={this.handleAddGroupChange}
                        name="isParent"
                        color="primary"
                        labelPlacement="start"
                      />
                    }
                    label="Is Parent"
                  />
                </Grid>
                {addGroupForm.isParent && (
                  <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                      <FettleAutocomplete
                        id="parent-group"
                        name="parentId"
                        label="Parent Group"
                        $dataSource={this.parentGroupDataSourceCallback$}
                        changeDetect={parentGroupChangeDetect}
                        onChange={(e, newValue) => this.handleParentGroupAutoCompleteChange(e, newValue, 'parentId')}
                      />
                    </FormControl>
                  </Grid>
                )}
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.handleAddGroup}>
              Go
            </Button>
            <Button onClick={this.addGroupModalToggle(false)} className="p-button-text">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default withRouter(withStyles(useStyles)(ServiceGroupingFormComponent));
