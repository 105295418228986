import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import { ProspectService } from '../../remote-api/api/client-services';
import { ClientTypeService } from '../../remote-api/api/master-services';
import { FettleDataGrid } from '../../shared-components';

const PAGE_NAME = 'PROSPECT';
const clienttypeervice = new ClientTypeService();

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
}));

const prospectService = new ProspectService();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['displayName'] = pageRequest.searchKey.trim();
    pageRequest['mobileNo'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return prospectService.getProspects(pageRequest).pipe(
    switchMap(data => {
      return clienttypeervice.getCleintTypes().pipe(
        map(ct => {
          data.content.forEach(cl => {
            ct.content.forEach(clienttype => {
              if (cl.clientType === clienttype.code) {
                cl['clientTypeName'] = clienttype.name;
              }
            });
          });
          return data;
        }),
      );
    }),
  );
};

// const dataSource$ = (pageRequest={ page: 0,
//     size: 10,
//     summary: true,
//     active: true}) => prospectService.getProspects(pageRequest);
const columnsDefinations = [
  { field: 'displayName', headerName: 'Name' },
  { field: 'code', headerName: 'Prospect Code' },
  { field: 'clientTypeName', headerName: 'Client Type' },
  { field: 'mobileNo', headerName: 'Contact Number' },
];
const xlsColumns = ['displayName', 'code', 'clientTypeName', 'mobileNo'];

class ProspectListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.configuration = {
      enableSelection: false,
      scrollHeight: '300px',
      pageSize: 10,
      actionButtons: this.hasAccess('UPDATE'),
      header: {
        enable: true,
        enableDownload: true,
        downloadbleColumns: xlsColumns,
        addCreateButton: this.hasAccess('CREATE'),
        onCreateButtonClick: this.handleOpen,
        text: 'Prospect Management',
        enableGlobalSearch: true,
        searchText: 'Search by code, name, mobile',
        //   onSelectionChange: handleSelectedRows,
        //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
        //   selectionMenuButtonText: "Action"
      },
    };
  }

  hasAccess = accessName => {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    const accessList = access_details.filter(ad => ad.indexOf(PAGE_NAME) > -1).map(ac => ac.split('_')[0]);
    const status = accessList.some(a => a.indexOf(accessName) > -1);
    if (accessName === 'UPDATE') {
      if (status) {
        return [
          {
            icon: 'pi pi-user-edit',
            className: 'ui-button-warning',
            onClick: this.openEditSection,
          },
        ];
      } else {
        return [];
      }
    } else {
      return status;
    }
  };

  handleOpen = () => {
    this.props.history.push('/client/prospects?mode=create');
  };

  openEditSection = prospect => {
    this.props.history.push(`/client/prospects/${prospect.id}?mode=edit`);
  };

  render() {
    return (
      <div>
        <FettleDataGrid
          $dataSource={dataSource$}
          config={this.configuration}
          columnsDefination={columnsDefinations}
          onEdit={this.openEditSection}
        />
      </div>
    );
  }
}
export default withRouter(ProspectListComponent);
