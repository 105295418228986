import { makeStyles } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { ClientService } from '../../remote-api/api/client-services';
import { ClientTypeService } from '../../remote-api/api/master-services';
import { FettleDataGrid } from '../../shared-components';
import { CloseOutlined } from '@material-ui/icons';
import { Box, Button, Modal, TextField, useTheme } from '@material-ui/core';

const clienttypeervice = new ClientTypeService();

const PAGE_NAME = 'CLIENT';
const roleService = new RoleService();

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
}));
const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  background: '#fff',
  // border: '2px solid #000',
  boxShadow: 24,
  padding: '2% 3%',
};
const clientService = new ClientService();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
    // contactNo:contactNum,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['name'] = pageRequest.searchKey.trim();
    pageRequest['fullName'] = pageRequest.searchKey.trim();
    pageRequest['contactNo'] = pageRequest.searchKey.trim();

    //   pageRequest["clientTypeCd"] = pageRequest.searchKey;
  }
  delete pageRequest.searchKey;
  return clientService
    .getClients(pageRequest)
    .pipe(
      map(data => {
        let content = data.content;
        let records = content.map(item => {
          item['clientBasicDetails']['primaryContact'] = item.clientBasicDetails.contactNos[0].contactNo;
          return item;
        });
        data.content = records;
        return data;
      }),
    )
    .pipe(
      switchMap(data => {
        return clienttypeervice.getCleintTypes().pipe(
          map(ct => {
            data.content.forEach(cl => {
              ct.content.forEach(clienttype => {
                if (cl.clientBasicDetails.clientTypeCd === clienttype.code) {
                  cl['clientTypeName'] = clienttype.name;
                }
              });
            });
            return data;
          }),
        );
      }),
    );
};


const columnsDefinations = [
  { field: 'clientBasicDetails.displayName', headerName: 'Name' },
  { field: 'clientBasicDetails.code', headerName: 'Client Code' },
  { field: 'clientTypeName', headerName: 'Client Type' },
  { field: 'clientBasicDetails.primaryContact', headerName: 'Contact Number' },
];
const xlsColumns = [
  'clientBasicDetails.displayName',
  'clientBasicDetails.code',
  'clientTypeName',
  'clientBasicDetails.primaryContact'
];

class ClientListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      contactNumber: '',
    };

    this.configuration = {
      enableSelection: false,
      scrollHeight: '300px',
      pageSize: 10,
      actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', this.openEditSection),
      header: {
        enable: true,
        enableDownload: true,
        downloadbleColumns: xlsColumns,
        addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
        onCreateButtonClick: this.handleOpen,
        text: 'Client Management',
        enableGlobalSearch: true,
        searchText: 'Search by code, name',
        selectionMenuButtonText: 'Advance Search',
      },
    };
  }

  contactClick = () => {
    this.setState({ showModal: true });
  };
  hasAccess = accessName => {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    const accessList = access_details.filter(ad => ad.indexOf(PAGE_NAME) > -1).map(ac => ac.split('_')[0]);
    const status = accessList.some(a => a.indexOf(accessName) > -1);
    if (accessName === 'UPDATE') {
      if (status) {
        return [
          {
            icon: 'pi pi-user-edit',
            className: 'ui-button-warning',
            onClick: this.openEditSection,
          },
        ];
      } else {
        return [];
      }
    } else {
      return status;
    }
  };

  handleOpen = () => {
    this.props.history.push('/client/clients?mode=create');
  };

  openEditSection = client => {
    this.props.history.push(`/client/clients/${client.id}?mode=edit`);
  };
  handleChange = e => {
    this.setState({ contactNumber: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    // Do something with the contact number, for example, send it to a server or perform validation
    // Close the modal
    this.closeModal();
  };
  render() {
    const { classes } = this.props;

    return (
      <div>
        <FettleDataGrid
          $dataSource={dataSource$}
          columnsDefination={columnsDefinations}
          onEdit={this.openEditSection}
          config={this.configuration}
          // reloadTable={reloadTable}
        />
      </div>
    );
  }
}
export default withRouter(withStyles(useStyles)(ClientListComponent));
