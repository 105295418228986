import { Button } from 'primereact/button';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import * as Icons from '@material-ui/icons';
import React, { Fragment, useRef } from 'react';
import { Menu as PMenu } from 'primereact/menu';


const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function FettleActionMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuLeft = useRef(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // const buildActionMenu = (button, index) => {

  //   return (<StyledMenuItem key={index} disabled={button.disabled} onClick={(e) => {
  //     e.stopPropagation();
  //     handleClose();
  //     Object.prototype.toString.call(button.onClick) == "[object Function]" && button.onClick();
  //   }}>
  //     {button.icon &&
  //       <Fragment>
  //         <ListItemIcon>
  //           {React.createElement(Icons[button.icon], { fontSize: "small" })}
  //         </ListItemIcon>
  //       </Fragment>
  //     }
  //     <ListItemText primary={button.text} />
  //   </StyledMenuItem>);
  // }

  // const buildActionMenus = () => {
  //   return props.menus.map(buildActionMenu)
  // }

  const items = props.menus.map((button, index) => ({
    label: button.text,
    icon: 'pi-arrow-up-right',
    command: () => {
      typeof button.onClick === 'function' && button.onClick();
    },
  }))


  return (
    <div>
      {props.menus.length > 0 && (
        <Button
          icon="pi pi-ellipsis-h"
          severity='secondary'
          className="mr-2"
          rounded
          raised
          text
          outlined
          style={{width: '32px', height: '32px'}}
          onClick={(event) => menuLeft.current.toggle(event)}
          aria-controls="popup_menu_left"
          aria-haspopup
          tooltip={props.title || 'Actions'}
          tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }}
        />
      )}
      <PMenu model={items} popup ref={menuLeft} id="popup_menu_left" popupAlignment="right" />
    </div>
  );

}

// import { Button } from 'primereact/button';
// import ListItemIcon from '@material-ui/core/ListItemIcon';
// import ListItemText from '@material-ui/core/ListItemText';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';
// import { withStyles } from '@material-ui/core/styles';
// import * as Icons from '@material-ui/icons';
// import React, { Fragment } from 'react';

// const StyledMenu = withStyles({
//   paper: {
//     border: '1px solid #d3d4d5',
//   },
// })((props) => (
//   <Menu
//     elevation={0}
//     getContentAnchorEl={null}
//     anchorOrigin={{
//       vertical: 'bottom',
//       horizontal: 'center',
//     }}
//     transformOrigin={{
//       vertical: 'top',
//       horizontal: 'center',
//     }}
//     {...props}
//   />
// ));

// const StyledMenuItem = withStyles((theme) => ({
//   root: {
//     '&:focus': {
//       backgroundColor: theme.palette.primary.main,
//       '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
//         color: theme.palette.common.white,
//       },
//     },
//   },
// }))(MenuItem);

// export default function FettleActionMenu(props) {
//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   const buildActionMenu = (button, index) => {

//     return (<StyledMenuItem key={index} disabled={button.disabled} onClick={(e) => {
//       e.stopPropagation();
//       handleClose();
//       Object.prototype.toString.call(button.onClick) == "[object Function]" && button.onClick();
//     }}>
//       {button.icon &&
//         <Fragment>
//           <ListItemIcon>
//             {React.createElement(Icons[button.icon], { fontSize: "small" })}
//           </ListItemIcon>
//         </Fragment>
//       }
//       <ListItemText primary={button.text} />
//     </StyledMenuItem>);
//   }

//   const buildActionMenus = () => {
//     return props.menus.map(buildActionMenu)
//   }




//   return (
//     <div>
//       <Button
//         aria-controls="customized-menu"
//         aria-haspopup="true"
//         variant="contained"
//         color="primary"
//         onClick={handleClick}
//       >
//         {props.title || 'Actions'}
//       </Button>
//       <StyledMenu
//         id="customized-menu"
//         anchorEl={anchorEl}
//         keepMounted
//         open={Boolean(anchorEl)}
//         onClose={handleClose}
//       >
//         {buildActionMenus()}
//       </StyledMenu>
//     </div>
//   );
// }
