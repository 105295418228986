import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import React from 'react';

export default function OTPDialogBox(props) {

    const { open } = props;

    const handleClose = () => {
        props.handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" maxWidth="xs" fullWidth disableBackdropClick>
            <DialogTitle id="form-dialog-title">OTP Box</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Please Enter the OTP
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="fettle-otp"
                    type="text"
                    fullWidth
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant="contained">
                    Submit
                </Button>
                <Button onClick={handleClose} color="primary" variant="outlined">
                    Resend OTP
                </Button>
            </DialogActions>
        </Dialog>
    );
}