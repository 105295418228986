import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import RoleService from '../../../services/utility/role';
import { ParametersService } from '../../remote-api/api/master-services';
import { FettleDataGrid } from '../../shared-components';

const PAGE_NAME = 'PARAMETER';
const roleService = new RoleService();

const parametersService = new ParametersService();
let parametersService$ = parametersService.getAllParameters();

const useStyles = theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
});

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  if (pageRequest.searchKey) {
    // pageRequest['code'] = pageRequest.searchKey;
    // pageRequest['type'] = pageRequest.searchKey;
    pageRequest['name'] = pageRequest.searchKey.trim();
    // pageRequest['contactNo'] = pageRequest.searchKey;
  }
  delete pageRequest.searchKey;
  return (parametersService$ = parametersService.getAllParameters(pageRequest));
};

const columnsDefinations = [{ field: 'name', headerName: 'Rule Name' }];

class ParametersListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      parameterList: [],
    };

    this.initConfig();
  }

  componentDidMount() {
    parametersService$.subscribe(response => {
      this.setState({
        ...this.state,
        parameterList: response.content,
      });
    });
  }

  initConfig = () => {
    this.configuration = {
      enableSelection: true,
      scrollHeight: '300px',
      pageSize: 10,
      actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', this.openEditSection),
      header: {
        enable: true,
        addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
        onCreateButtonClick: this.handleOpen,
        text: 'Parameter Management',
        enableGlobalSearch: true,
        //   onSelectionChange: handleSelectedRows,
        //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
        //   selectionMenuButtonText: "Action"
      },
    };
  };

  hasAccess = accessName => {
    const access_details = JSON.parse(localStorage.getItem('access_details'));
    const accessList = access_details.filter(ad => ad.indexOf(PAGE_NAME) > -1).map(ac => ac.split('_')[0]);
    const status = accessList.some(a => a.indexOf(accessName) > -1);
    if (accessName === 'UPDATE') {
      if (status) {
        return [
          {
            icon: 'pi pi-user-edit',
            className: 'ui-button-warning',
            onClick: this.openEditSection,
          },
        ];
      } else {
        return [];
      }
    } else {
      return status;
    }
  };

  handleOpen = () => {
    this.props.history.push('/masters/parameters?mode=create');
  };

  openEditSection = row => {
    this.props.history.push(`/masters/parameters/${row.id}?mode=edit`);
  };

  render() {
    const { classes } = this.props;
    return (
      
        <FettleDataGrid
          $dataSource={dataSource$}
          config={this.configuration}
          columnsDefination={columnsDefinations}
          onEdit={this.openEditSection}
        />
    );
  }
}
export default withRouter(withStyles(useStyles)(ParametersListComponent));
