import { withStyles } from '@material-ui/styles';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { map } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { UsersService } from '../../remote-api/api/user-management-service';
import { FettleDataGrid } from '../../shared-components';

const PAGE_NAME = 'USER';
const roleService = new RoleService();

const usersService = new UsersService();
let usersService$ = usersService.getUsers();

const useStyles = theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
});

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  if (pageRequest.searchKey) {
    pageRequest['firstName'] = pageRequest.searchKey.trim();
    pageRequest['lastName'] = pageRequest.searchKey.trim();
    pageRequest['userName'] = pageRequest.searchKey.trim();
    pageRequest['email'] = pageRequest.searchKey.trim();
    pageRequest['roles'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return (usersService$ = usersService.getUsers(pageRequest).pipe(
    map(res => {
      const newContent = res?.content?.map(v => ({
        ...v,
        roleList: v.roles.join(', '),
      }));
      res.content = newContent;
      return res;
    }),
  ));
};

const columnsDefinations = [
  { field: 'userName', headerName: 'Username' },
  { field: 'email', headerName: 'Email' },
  { field: 'roleList', headerName: 'Roles' },
  
];

class UsersListComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userList: [],
    };

    this.initConfig();
  }

  componentDidMount() {
    usersService$.subscribe(response => {
      this.setState({
        ...this.state,
        userList: response.content,
      });
    });
  }

  initConfig = () => {
    this.configuration = {
      enableSelection: true,
      scrollHeight: '300px',
      pageSize: 10,
      actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', this.openEditSection),
      header: {
        enable: true,
        addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
        onCreateButtonClick: this.handleOpen,
        text: 'Users Management',
        enableGlobalSearch: true,
        //   onSelectionChange: handleSelectedRows,
        //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
        //   selectionMenuButtonText: "Action"
      },
    };
  };

  handleOpen = () => {
    this.props.history.push('/user-management/users?mode=create');
  };

  openEditSection = row => {
    this.props.history.push(`/user-management/users/${row.userName}?mode=edit`);
  };

  render() {
    const { classes } = this.props;
    return (
      
        <FettleDataGrid
          $dataSource={dataSource$}
          config={this.configuration}
          columnsDefination={columnsDefinations}
          onEdit={this.openEditSection}
        />
    );
  }
}
export default withRouter(withStyles(useStyles)(UsersListComponent));
