import * as React from "react";
import { useParams } from "react-router-dom";
import { ReimbursementService } from "../../remote-api/api/claims-services";
import { REIM_STATUS_MSG_MAP } from "./shared";
import { FettleDocTimeline } from "../../shared-components/components/fettle.doc.timeline";

const reimService = new ReimbursementService();

export default function ClaimsTimelineComponent(props) {
  
  const { id } = useParams();
  const [timeLine, setTimeLine] = React.useState([]);
  if (!timeLine.length) {
    if (id || props.id) {
        reimService
        .getReimbursementById(id)
        .subscribe((preAuth) => {
          let data = preAuth.timeLine || [];
          let tl = data.map((timeLine) => ({
            timestamp: new Date(timeLine.dateTime),
            title: REIM_STATUS_MSG_MAP[timeLine.status],
            description: timeLine.comment || "--",
          }));
          setTimeLine([...tl]);
        });
    }
  }
  return <FettleDocTimeline timeline={timeLine} />;
}
