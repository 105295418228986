import { Snackbar } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Box from '@material-ui/core/Box';
import { Button } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
// import Chip from '@material-ui/core/Chip';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Alert, Autocomplete } from '@material-ui/lab';
import ChipInput from 'material-ui-chip-input';
import React, { useEffect, useState } from 'react';
import { MemberService } from '../remote-api/api/member-services';
import AddIcon from '@material-ui/icons/Add';
import { Pages, Search } from '@material-ui/icons';
import { ProvidersService } from '../remote-api/fettle-remote-api';

const memupservice = new MemberService();
const providerservice = new ProvidersService();
// const currencyservice = new CurrencyService();

// let cs$ = currencyservice.getCurrencies();
let md$ = memupservice.getMemberDetails();
let datasrc$ = memupservice.geAPIList();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  formControl: {
    minWidth: 182,
  },
  formControl1: {
    minWidth: 300,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    // flexBasis: '33.33%',
    flexShrink: 0,
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ClaimsReportComponent(props) {
  const classes = useStyles();
  const [fieldList, setFieldList] = React.useState([]);
  // const [renderTypeList, setRenderTypeList] = React.useState([]);
  // const [datatypeList, setDataTypeList] = React.useState([]);
  // const [currencyList, setCurrencyList] = React.useState([]);
  // const [AlertMsg, setAlertMsg] = React.useState('');
  const [snack, setSnack] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    msg: '',
  });
  const [snack1, setSnack1] = React.useState({
    open: false,
    vertical: 'top',
    horizontal: 'right',
    msg: '',
  });

  const [sourceList, setSourceList] = React.useState([]);
  const [selectedFieldlist, setSelectedFieldlist] = React.useState([]);
  const [handleLayout, setHandleLayout] = React.useState();
  // const [fieldOptions, setFieldOptions] = React.useState([]);
  const [expanded, setExpanded] = React.useState('panel1');
  const [memberDetailList, setMemberDetailsList] = React.useState([]);
  const [providerDetailList, setProviderDetailList] = React.useState([]);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  const useObservable1 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            ele['isMandatory'] = false;
            ele['isUnique'] = false;
            ele['errorCode'] = '';
            ele['errorMessage'] = '';
            ele['customValues'] = [];
            ele['sourceApiId'] = '';
            ele['sourceType'] = '';
            if (ele.dataType !== 'CURRENCY' && ele.dataType !== 'DATE') {
              ele['isDropdown'] = true;
            }
            if (ele.dataType === 'CURRENCY' || ele.dataType === 'DATE') {
              ele['isDropdown'] = false;
            }
          });
        }
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  // useObservable(cs$, setCurrencyList);
  useObservable1(md$, setMemberDetailsList);
  useObservable(datasrc$, setSourceList);

  const handleSnackClose = (event, reason) => {
    setSnack({
      open: false,
      vertical: 'top',
      horizontal: 'right',
      msg: '',
    });
  };
  const handleSnack1Close = (event, reason) => {
    setSnack1({
      open: false,
      vertical: 'top',
      horizontal: 'right',
      msg: '',
    });
  };

  const handleField = (e, index) => {
    const { name, value } = e.target;

    const list = [...fieldList];
    list[index][name] = value;

    setFieldList(list);
  };

  const handleFieldChecked = (e, index) => {
    const { name, checked } = e.target;
    const list = [...fieldList];

    if (name === 'isDropdown' && !checked) {
      list[index]['sourceType'] = '';
      list[index]['sourceApiId'] = '';
      list[index]['customValues'] = [];
    }
    list[index][name] = checked;

    setFieldList(list);
  };

  const handleAddChip = (chip, i) => {
    const list = [...fieldList];
    list[i].customValues.push(chip);
    setFieldList(list);
  };

  const handleDeleteChip = (chip, index, i) => {
    const list = [...fieldList];
    list[i].customValues.splice(index, 1);
    setFieldList(list);
  };

  const saveConfigurations = () => {
    let fields = [];
    let rules = [];
    let allOK = true;
    fieldList.forEach((val, index) => {
      //Validation
      if (val.isDropdown) {
        if (val.sourceType === '') {
          setSnack({
            open: true,
            vertical: 'top',
            horizontal: 'right',
            msg: 'Please choose a source type',
          });
          allOK = false;
          return;
        }

        if (val.sourceType === 'DYNAMIC' && val.sourceApiId === '') {
          setSnack({
            open: true,
            vertical: 'top',
            horizontal: 'right',
            msg: 'Please choose a source api',
          });
          allOK = false;
          return;
        }
        if (val.sourceType === 'CUSTOM' && val.customValues.length === 0) {
          setSnack({
            open: true,
            vertical: 'top',
            horizontal: 'right',
            msg: 'Please enter custom datas',
          });
          allOK = false;
        }
      }

      if (val.formattingAllowed && !val.format) {
        setSnack({
          open: true,
          vertical: 'top',
          horizontal: 'right',
          msg: 'Please choose a format',
        });
        allOK = false;
      }

      //Payload

      let obj = {
        name: val['columnName'],
        required: val['isMandatory'],
        dataType: val['dataType'],
        systemGenerated: val['systemGenerated'],
      };

      if (val.formattingAllowed) {
        obj['format'] = val['format'];
      }
      if (!val.formattingAllowed) {
        obj['format'] = '';
      }

      if (val.sourceType === 'DYNAMIC') {
        obj['sourceApiId'] = val['sourceApiId'];
        obj['customValues'] = [];
      } else if (val.sourceType === 'CUSTOM') {
        obj['customValues'] = val['customValues'];
        obj['sourceApiId'] = '';
      } else {
        obj['sourceApiId'] = '';
        obj['customValues'] = [];
      }
      fields.push(obj);

      if (val.isUnique) {
        rules.push({
          rule: '$(member_field_value_not_exist.' + val.columnName + ') == true',
          name: val.columnName,
          errorCode: val.errorCode,
          errorMessage: val.errorMessage,
        });
      }
    });
    let payload = {
      fields: fields,
      rules: rules,
      stopIfFirstRuleFail: true,
      headerRowNum: 0,
      startDataRowNum: 1,
    };
    if (allOK) {
      memupservice.saveMemberConfigs(payload).subscribe(res => {
        setSnack1({
          open: true,
          vertical: 'top',
          horizontal: 'right',
          msg: 'Member Upload Configuration saved successfully',
        });
      });
    }
  };

  useEffect(() => {
    providerservice.getProvidersList().subscribe(res => {
      console.log(res);
      setProviderDetailList(res);
    });
  }, [handleLayout]);
  // const onDragEnd = (data) => {
  //     setFieldList(data);
  // }
  const handleAccordianToggle = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleFieldList = event => {
    setSelectedFieldlist(event.target.value);
  };
  const addFields = event => {
    setFieldList(selectedFieldlist);
  };

  console.log(providerDetailList);

  const claimsComponent = status => {
    switch (status) {
      case 'remittance':
        return (
          <Box>
            <h2 style={{ marginBottom: '1rem' }}>Remittance for Provider</h2>
            <Paper
              style={{
                display: 'flex',
                justifyContent: 'space-around',
                padding: '5rem',
              }}>
              <Box>
                <Autocomplete
                  disablePortal
                  options={providerDetailList}
                  style={{ width: 300 }}
                  getOptionLabel={option => option.name || ''}
                  renderInput={params => <TextField {...params} label="Providers" variant="outlined" />}
                />
              </Box>

              <Box sx={{ width: '30%', display: 'flex', justifyContent: 'space-around' }}>
                <TextField
                  label="Start Date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true, // This will ensure the label doesn't overlap the selected date
                  }}
                />
                <TextField
                  label="End Date"
                  variant="outlined"
                  type="date"
                  InputLabelProps={{
                    shrink: true, // Same here to handle date input properly
                  }}
                />
              </Box>
            </Paper>
            <Box sx={{display:"flex",justifyContent:"center"}}>
            <Button
              variant="contained"
              // type="submit"
              style={{ backgroundColor: '#ed2264', color: 'white', margin: '2rem' }}
              //   disabled={disable}>
            >
              Generate Reports
              <Search style={{ width: '15px', marginLeft: '5px' }} />
            </Button>
            </Box>
          </Box>
        );
      default:
        return (
          <div>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '20px',
                height: '2em',
                fontSize: '18px',
              }}>
              <span
                style={{
                  fontWeight: '600',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  marginLeft: '5px',
                }}>
                Report -Claim
              </span>
            </Grid>
            <Paper
              elevation="none"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                padding: '1%',
              }}>
              <Box padding={'10px'} display={'flex'} flexDirection={'row'} flexWrap={'wrap'} gridGap={'10px'}>
                <Paper
                  elevation="10px"
                  style={{
                    borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '1%',
                  }}>
                  <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
                    <Box display={'flex'} justifyContent={'space-between'} style={{cursor:"pointer"}} onClick={() => setHandleLayout('remittance')}>
                      <Typography align="start" variant="h3" >
                        Remittance For Provider
                      </Typography>
                      {/* <Button
                    variant="contained"
                    color="primary"
                    className={classes.buttonPrimary}
                    style={{ marginLeft: '5px' }}
                    // onClick={handleAddClaimCost}
                   > */}
                      <AddIcon />
                      {/* </Button> */}
                    </Box>
                  </Box>
                </Paper>
                <Paper
                  elevation="10px"
                  style={{
                    borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
                    display: 'flex',
                    // background: 'wheat',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '1%',
                  }}>
                  <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography align="center" variant="h4">
                        Report-2
                      </Typography>
                      <AddIcon />
                    </Box>
                  </Box>
                </Paper>
                <Paper
                  elevation="10px"
                  style={{
                    borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
                    display: 'flex',
                    // background: 'wheat',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '1%',
                  }}>
                  <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography align="center" variant="h4">
                        Report-3
                      </Typography>
                      <AddIcon />
                    </Box>
                  </Box>
                </Paper>
                <Paper
                  elevation="10px"
                  style={{
                    borShadow: '1px 1px 2px 0px rgba(0,0,0,1)',
                    display: 'flex',
                    // background: 'wheat',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    padding: '1%',
                  }}>
                  <Box width={'250px'} height={'300px'} boxShadow={'0 1px 3px 0 rgba(0, 0, 0, 0.15)'} padding={'5px'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography align="center" variant="h4">
                        Report-4
                      </Typography>
                      <AddIcon />
                    </Box>
                  </Box>
                </Paper>
              </Box>
            </Paper>
          </div>
        );
    }
  };
  return claimsComponent(handleLayout);
}
