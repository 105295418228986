import { ReactKeycloakProvider } from '@react-keycloak/web';
import { ConnectedRouter } from 'connected-react-router';
import jwt_decode from 'jwt-decode';
import React, { useState } from 'react';
import './App.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { Provider } from 'react-redux';
import { Switch } from 'react-router-dom';
import AppWrapper from './@jumbo/components/AppWrapper';
import AppContextProvider from './@jumbo/components/contextProvider/AppContextProvider';
import keycloak from './keycloak';
import { FettleGlobalErrorMessageSnackbar } from './modules/shared-components/components/fettle.global.error.message.snackbar';
import configureStore, { history } from './redux/store';
import Routes from './routes';
import { Box, CircularProgress, Grid } from '@material-ui/core';
import { useTabContext } from '@material-ui/lab';

export const store = configureStore();

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const eventLogger = (event, error) => {};

  const tokenLogger = tokens => {
    const { name, email, preferred_username, resource_access, userType } = jwt_decode(tokens.token);
    
    const user_details = { name, email, preferred_username };
    const access_details = [];
    for (let key in resource_access) {
      const filteredArray = resource_access[key].roles.filter(n => {
        return access_details.indexOf(n) === -1;
      });
      access_details.push(...filteredArray);
    }
    
    // TODO: Remove me
    access_details.push('CLAIM');
    localStorage.setItem('user_details', JSON.stringify(user_details));
    localStorage.setItem('access_details', JSON.stringify(access_details));
    if(userType){
      localStorage.setItem('userType', userType);
    } else {
      localStorage.removeItem('userType');
    }
    window.getToken = () => tokens.token;
    setIsLoading(false);
  };
  const LoadingComponent = () => {
    return (
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"} height={"75vh"}>
        <CircularProgress color="secondary" />
      </Box>
    );
  };
  return (
    <div className="app">
      <ReactKeycloakProvider
        authClient={keycloak}
        onEvent={eventLogger}
        initOptions={{ onLoad: 'login-required' }}
        onTokens={tokenLogger}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <AppContextProvider>
              <AppWrapper>
                {isLoading ? (
                  <LoadingComponent />
                ) : (
                  <>
                    {' '}
                    <Switch>
                      <Routes />
                    </Switch>
                    <FettleGlobalErrorMessageSnackbar />
                  </>
                )}
              </AppWrapper>
            </AppContextProvider>
          </ConnectedRouter>
        </Provider>
      </ReactKeycloakProvider>
    </div>
  );
};

export default App;
