import { Divider, Grid, Input, TextField } from '@material-ui/core';
import { Button } from 'primereact/button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import 'date-fns';
import { useEffect } from 'react';
import * as React from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import downloadFileResponseExecution from '../../util/file.util';
import { REIM_STATUS_MSG_MAP } from './reim.shared';
import { useLocation, useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { MemberService } from '../../remote-api/api/member-services';
import { BenefitService, ProvidersService, ServiceTypeService } from '../../remote-api/fettle-remote-api';
import reimReviewModel from './reim.shared';
import { forkJoin } from 'rxjs';
import { ReimbursementService } from '../../remote-api/api/claims-services/claim.reimbursement.services';
export default function ClaimReimReview(props) {

  const [claimReimDetails, setclaimReimDetails] = React.useState(reimReviewModel())
  const { id } = useParams();
  const history = useHistory();
  const [fullWidth, setFullWidth] = React.useState(true);
  const [comment, setComment] = React.useState(null);
  const [cnfText, setCnfText] = React.useState(null);
  const [disableCalculateButton, setDisableCalculateButton] = React.useState(true);
  const handleClose = () => {
    props.onClose();
  };

  const reimService = new ReimbursementService();
  const memberservice = new MemberService();
  const benefitService = new BenefitService();
  const providerService = new ProvidersService();
  const serviceDiagnosis = new ServiceTypeService();

  useEffect(() => {
    if (id) {
      populateReimbursement();
    }
  }, [id]);

  const populateReimbursement = () => {
    let serviceAll$ = forkJoin(
      serviceDiagnosis.getServicesbyId('867854950947827712', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855014529282048', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855088575524864', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
      serviceDiagnosis.getServicesbyId('867855148155613184', {
        page: 0,
        size: 1000,
        summary: true,
        active: true,
        nonGroupedServices: false,
      }),
    );


    let frk$ = forkJoin({
      providers: providerService.getProviders(),
      bts: benefitService.getAllBenefit({ page: 0, size: 1000 }),
      reimDetails: reimService.getReimbursementById(id),
      services: serviceAll$
    })
    frk$.subscribe(data => {
      if (data.reimDetails.invoices && data.reimDetails.invoices.length !== 0) {
        data.providers.content.forEach(proAll => {
          data.reimDetails.invoices.forEach(pr => {
            if (proAll.id === pr.provideId) {
              pr['providerName'] = proAll.providerBasicDetails?.name
            }
          })
        })
      }

      data.bts.content.forEach(benall => {
        data.reimDetails.benefitsWithCost.forEach(benefit => {
          if (benefit.benefitId === benall.id) {
            benefit['benefitName'] = benall.name
          }
        })
      })
      let serviceList = [];
      data.services.forEach(ser => {
        ser.content.forEach(sr => {
          serviceList.push(sr);
        })
      })
      // serviceList.forEach(ser => {
      //   data.preAuth.services.forEach(service => {
      //     if(service.serviceId === ser.id){
      //       service['serviceName'] = ser.name;
      //     }
      //   })
      // })
      let pageRequest = {
        page: 0,
        size: 10,
        summary: true,
        active: true,
        key: 'MEMBERSHIP_NO',
        value: data.reimDetails.memberShipNo,
        key1: 'policyNumber',
        value1: data.reimDetails.policyNumber
      }
      memberservice.getMember(pageRequest).subscribe(res => {
        if (res.content?.length > 0) {
          const member = res.content[0];
          let obj = {
            member: member,
            reim: data.reimDetails
          }
          setclaimReimDetails(obj);
        }
      });
    })

    // preAuthService.getPreAuthById(id).subscribe(preAuth => {
    //     let pageRequest = {
    //         page: 0,
    //         size: 10,
    //         summary: true,
    //         active: true,
    //         key:'MEMBERSHIP_NO',
    //         value:preAuth.memberShipNo,
    //         key1:'policyNumber',
    //         value1:preAuth.policyNumber
    //       }
    //       memberservice.getMember(pageRequest).subscribe(res=>{
    //         if(res.content?.length > 0){
    //           const member= res.content[0];
    //           setclaimReimDetails({member,preAuth});
    //         }
    //       });  

    // });
  }

  const handleModalSubmit = () => { };

  const buildBenefitWithCostView = (bwc) => {
    return (
      <Grid container>
        <Grid item xs={3}>{bwc.benefitName}</Grid>
        <Grid item xs={3}>{bwc.maxApprovedCost}</Grid>
        <Grid item xs={3}>{bwc.copayAmount}</Grid>
      </Grid>
    );
  }


  const downloadDoc = (doc) => {
    reimService.downloadDoc(id, doc.documentName)
      .subscribe(res => downloadFileResponseExecution(res));
  }

  const buildInvoicesView = (provider) => {
    return (
      <Grid container>
        <Grid item xs={3}>{provider.providerName}</Grid>
        <Grid item xs={3}>{provider.invoiceAmount}</Grid>
        <Grid item xs={3}>{provider.invoiceNo}</Grid>
        <Grid item xs={3}>{provider.transactionNo}</Grid>
      </Grid>
    );
  }

  const buildServiceWithCostView = (service) => {
    return (
      <Grid container>
        <Grid item xs={4}>{service.serviceName}</Grid>
        <Grid item xs={8}>{service.estimatedCost}</Grid>
      </Grid>
    );
  }

  const buildDocumentView = (doc) => {
    return (
      <Grid container>
        <Grid item xs={3}>{doc.documentType}</Grid>
        <Grid item xs={7}>{doc.documentOriginalName}</Grid>
        <Grid item xs={2}><ArrowDownwardIcon style={{ cursor: 'pointer' }} onClick={() => downloadDoc(doc)}></ArrowDownwardIcon></Grid>
      </Grid>
    );
  }

  const requestForCalculate = () => {
    reimService.editReimbursement({}, id, "calculate").subscribe(r => {
      // let model = { ...ReimReviewModel };
      // model.preAuth = { ...model.preAuth, calculationStatus: 'INPROGRESS' };
      // props.setState(model)
      populateReimbursement();
    });
  }

  const requestForStartReview = () => {
    reimService.editReimbursement({}, id, "evs").subscribe(r => {
      populateReimbursement();
    });
  }

  const showCalculateButton = () => {
    return (
      claimReimDetails.reim.reimbursementStatus == 'EVALUATION_INPROGRESS' &&
      <Button onClick={requestForCalculate} disabled={claimReimDetails.reim.calculationStatus == 'INPROGRESS'}>
        {claimReimDetails.reim.calculationStatus == 'INPROGRESS' ? 'Calculating...' : 'Calculate'}
      </Button>
    );
  }

  const showReviewStartButton = () => {
    return (
      claimReimDetails.reim.reimbursementStatus == 'REQUESTED' &&
      <Button onClick={requestForStartReview}>Start Review</Button>
    );
  }


  const onDecission = (decission) => {

    if ((decission == 'APPROVED' && cnfText === 'approve') || (decission == 'REJECTED' && cnfText === 'reject')) {
      reimService.editReimbursement({ decission: decission, comment: comment }, id, "decission").subscribe(r => {
        // window.location.reload();
        history.push('/claims/claims-reimbursement?mode=viewList');

      });
    }


  }


  const showApprovedAndRejectButtons = () => {

    if (claimReimDetails.reim.reimbursementStatus == 'EVALUATION_INPROGRESS' || claimReimDetails.reim.reimbursementStatus == 'APPROVED_FAILED') {
      return (
        <React.Fragment>
          <Button disabled={claimReimDetails.reim.calculationStatus != 'COMPLETED'} onClick={() => onDecission('APPROVED')}>Approve</Button>
          <Button onClick={() => onDecission('REJECTED')}>Reject</Button>
        </React.Fragment>
      );
    }
    else {
      return null;
    }

  }

  const showReviewerComment = () => {

    if (claimReimDetails.reim.reimbursementStatus == 'APPROVED' || claimReimDetails.reim.reimbursementStatus == 'REJECTED') {
      return (
        <div style={{ padding: '5px' }}>
          <strong>Reviewer comment</strong>
          <Divider />
          <Grid container>
            <Grid item xs={12}>
              <p>
                {claimReimDetails.reim.comment}
              </p>
            </Grid>
          </Grid>
        </div>
      );
    }
    else {
      return null;
    }

  }

  const handleChangeOfDecitionText = (event) => {
    setCnfText(event.target.value);
  };

  const handleChangeOfCommentText = (event) => {
    setComment(event.target.value);
  };

  const showCommentBox = () => {
    if (claimReimDetails.reim.reimbursementStatus == 'EVALUATION_INPROGRESS' || claimReimDetails.reim.reimbursementStatus == 'APPROVED_FAILED') {
      return (<div style={{ padding: '5px' }}>
        <strong>Reviewer input</strong>
        <Divider />
        <Grid container rowSpacing={5}>
          <Grid item xs={12}>
            <TextField required fullWidth label="Type approve or reject for respective operation" id="fullWidth" onChange={handleChangeOfDecitionText} />
          </Grid>
          <hr />
          <Grid item xs={12}>
            <TextField
              required
              id="filled-multiline-static"
              label="Add comment"
              multiline
              fullWidth
              rows={4}
              variant="filled"
              onChange={handleChangeOfCommentText}
            />
          </Grid>
        </Grid>


      </div>);
    }
  }

  return (
    <>
      <Grid container>
        <Grid item xs={8}>Reimbursement : {id}</Grid>
        <Grid item xs={4}><span style={{ float: 'right' }}>{REIM_STATUS_MSG_MAP[claimReimDetails.reim.reimbursementStatus]}</span></Grid>
      </Grid>



      <>
        <div style={{ padding: '5px' }}>
          <strong>Member details</strong>
          <Divider />

          <Grid container spacing={0.5}>
            <Grid item xs={3}>
              <span>Membership No: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.member.membershipNo}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Name: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.member.name}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Age: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.member.age}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Relations: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.member.relations}</span>
            </Grid>
          </Grid>

        </div>

        {/* <!-- policy details --> */}

        <div style={{ padding: '5px' }}>
          <strong>Policy details</strong>
          <Divider />

          <Grid container spacing={0.5}>
            <Grid item xs={3}>
              <span>Policy No: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.member.policyNumber}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Policy start date: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{new Date(claimReimDetails.member.policyStartDate).toLocaleDateString()}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Policy end date: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{new Date(claimReimDetails.member.policyEndDate).toLocaleDateString()}</span>
            </Grid>
          </Grid>
        </div>


        {/* <!-- Other details --> */}

        <div style={{ padding: '5px' }}>
          <strong>Other details</strong>
          <Divider />

          <Grid container spacing={0.5} rowSpacing={1}>
            <Grid item xs={3}>
              <span>Date of admission: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{new Date(claimReimDetails.reim.expectedDOA).toLocaleDateString()}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Date of  discharge: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{new Date(claimReimDetails.reim.expectedDOD).toLocaleDateString()}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Contact No1: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.reim.contactNoOne}</span>
            </Grid>
            <Grid item xs={3}>
              <span>Contact No2: </span>
            </Grid>
            <Grid item xs={9}>
              <span>{claimReimDetails.reim.contactNoTwo || 'NA'}</span>
            </Grid>

            <Grid item xs={3}>
              <span>Diagnosis: </span>
            </Grid>

            <Grid item xs={9}>
              <ul>
                {claimReimDetails.reim.diagnosis?.map(d => (<li>{d}</li>))}
              </ul>
            </Grid>

            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <span>Benefits: </span>
            </Grid>

            <Grid container>
              <Grid item xs={3}><strong>Name</strong></Grid>
              <Grid item xs={3}><strong>Approved Cost</strong></Grid>
              <Grid item xs={3}><strong>Copay</strong></Grid>
            </Grid>
            <Grid item xs={12}>
              {
                claimReimDetails.reim.benefitsWithCost.map(
                  (bwc) => buildBenefitWithCostView(bwc))
              }
            </Grid>

            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <span>Invoices: </span>
            </Grid>

            <Grid container>
              <Grid item xs={3}><strong>Provider Name</strong></Grid>
              <Grid item xs={3}><strong>Invoice Amount</strong></Grid>
              <Grid item xs={3}><strong>Invoice Number</strong></Grid>
              <Grid item xs={3}><strong>Transaction Number</strong></Grid>
            </Grid>

            <Grid item xs={12}>
              {claimReimDetails.reim.invoices && claimReimDetails.reim.invoices.length !== 0 &&
                claimReimDetails.reim.invoices.map(
                  (inv) => buildInvoicesView(inv))
              }
            </Grid>


            {/* <Grid item xs={12} style={{ marginTop: '1em' }}>
              <span>Service Details: </span>
            </Grid>

            <Grid container>
              <Grid item xs={4}><strong>Name</strong></Grid>
              <Grid item xs={8}><strong>Estimated cost</strong></Grid>
            </Grid>

            <Grid item xs={12}>
              {
                claimReimDetails.reim.services.map(
                  (s) => buildServiceWithCostView(s))
              }
            </Grid> */}


            <Grid item xs={12} style={{ marginTop: '1em' }}>
              <span>Docs: </span>
            </Grid>

            <Grid container>
              <Grid item xs={3}><strong>Doc type</strong></Grid>
              <Grid item xs={7}><strong>Name</strong></Grid>
              <Grid item xs={2}></Grid>
            </Grid>

            <Grid item xs={12}>
              {
                claimReimDetails.reim.documents.map(
                  (d) => buildDocumentView(d))
              }
            </Grid>
          </Grid>
        </div>
        <Grid container>
          {showCommentBox()}

          {showReviewerComment()}

        </Grid>
      </>



      <Grid container>
        {showReviewStartButton()}
        {showCalculateButton()}
        {showApprovedAndRejectButtons()}
      </Grid>

    </>

  );
}
