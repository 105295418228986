import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { map } from 'rxjs/operators';
import RoleService from '../../../services/utility/role';
import { AgentsService } from '../../remote-api/api/agents-services';
import { AgentTypeService } from '../../remote-api/api/master-services';
import { FettleDataGrid } from '../../shared-components';

const agenttypeservice = new AgentTypeService();

const roleService = new RoleService();

const PAGE_NAME = 'AGENT';

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
}));

const agentsService = new AgentsService();

let acl$ = agentsService.getAgentCommissionList();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['type'] = pageRequest.searchKey.trim();
    pageRequest['name'] = pageRequest.searchKey.trim();
    pageRequest['contactNo'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return agentsService.getAgentCommissionList(pageRequest).pipe(
    map(data => {
      let content = data.content;
      let records = content.map(item => {
        item['validFrom'] = moment(item.validFrom).format('DD/MM/YYYY');
        return item;
      });
      data.content = records;
      return data;
    }),
  );
};

// const getData =()=>{
//     let subscription = acl$?.subscribe((result) => {
//         // return result;
//     })
// }

// useEffect(()=>{
//     getData();
// },[])

// const dataSource$ = (pageRequest = {
//   page: 0,
//   size: 5,
//   summary: true,
//   active: true
// }) => {
//   agentsService.getAgents(pageRequest).
//     map(val => {
//     val.content.forEach(ele => {
//       ele['primaryContact'] = ele.agentBasicDetails.contactNos[0].contactNo
//     })
//     return val
//   })
// };
const columnsDefinations = [
  { field: 'clientType', headerName: 'Client Type' },
  { field: 'agentType', headerName: 'Agent Type' },
  { field: 'validFrom', headerName: 'Valid From' },
  //   { field: 'agentBasicDetails.primaryContact', headerName: 'Contact Number' },
];

function CommissionListComponent(props) {
  const history = useHistory();
  const [rows, setRows] = React.useState(props.rows);

  const classes = useStyles();

  if (localStorage.getItem('agentId')) {
    localStorage.removeItem('agentId');
  }

  const handleOpen = () => {
    history.push('/agents/commission?mode=create');
  };

  React.useEffect(() => {
    setRows(props.rows);
  }, [props.rows]);

  const openEditSection = agent => {
    history.push(`/agents/commission/${agent?.id}?mode=edit`);
  };
  const xlsColumns  = ['clientType', 'agentType', 'validFrom']
  const configuration = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: roleService.checkActionPermission(PAGE_NAME, 'UPDATE', openEditSection),
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Agent Commision',
      enableGlobalSearch: true,
      searchText: 'Search by code, name, type, contact',
    },
  };

  return (
    <div>
      {/* <DataGrid rows={rows} columns={props.columns} pageSize={10} /> */}
      <FettleDataGrid
        $dataSource={dataSource$}
        config={configuration}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
      />
    </div>
  );
}

export default CommissionListComponent;
