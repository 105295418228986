import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import MinimizeIcon from '@material-ui/icons/Minimize';
import React from 'react';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10,
    marginLeft: 15,
  },
  root: {
    maxWidth: 550,
    flex: '1 0 0',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
  },
  accContainer: {
    boxShadow: 'none !important',
  },
  AccordionSummary: {
    minHeight: '0 !important',
    backgroundColor: theme.palette.secondary.main,
    height: '0 !important',
  },
  cardBackground: {
    backgroundColor: theme.palette.background.default,
  },
}));

export default function FettleWidget(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    props.handleClose();
  };

  const doFullScreen = () => {
    props.doFullScreen(true, props);
  };

  return (
    <Card className={classes.root} style={{ boxShadow: 'none', border: '1px solid rgba(0, 0, 0, 0.1' }}>
      <CardHeader
        avatar={
          <Avatar aria-label="caption" className={classes.avatar}>
            {props.caption}
          </Avatar>
        }
        action={
          <CardActions disableSpacing>
            <IconButton aria-label="minimize" onClick={() => handleExpandClick()}>
              <MinimizeIcon />
            </IconButton>
            <IconButton aria-label="fullscreen" onClick={() => doFullScreen()}>
              <FullscreenIcon />
            </IconButton>
            <IconButton aria-label="close" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </CardActions>
        }
        title={props.title}
        subheader=""
        className={classes.cardBackground}
      />
      <CardContent>
        <Accordion expanded={expanded} className={classes.accContainer} elevation="none">
          <AccordionSummary className={classes.AccordionSummary}></AccordionSummary>
          <AccordionDetails className={classes.details}>{props.widContent}</AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
}
