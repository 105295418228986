import DateFnsUtils from '@date-io/date-fns';
import Box from '@material-ui/core/Box';
import { Button } from 'primereact/button';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MuiAlert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import { useFormik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { concat, forkJoin, of, zip } from 'rxjs';
import * as yup from 'yup';
import { AgentsService } from '../../remote-api/api/agents-services';
import { InvoiceService } from '../../remote-api/api/invoice-services';
import { ClientTypeService, OrganizationTypeService, TPAService } from '../../remote-api/api/master-services';
import { PlanService } from '../../remote-api/api/plan-services';
import { PolicyService } from '../../remote-api/api/policy-services';
import { QuotationService } from '../../remote-api/api/quotation-services';
import { TaxService } from '../../remote-api/api/tax-services';
import EditConfirmationModal from './modals/edit.client.modal.component';
import ProposerAgentModal from './modals/policy.agent.import.modal.component';
import { ReceiptService } from '../../remote-api/fettle-remote-api';

const organizationservice = new OrganizationTypeService();
const clienttypeervice = new ClientTypeService();
const taxservice = new TaxService();
const invoiceservice = new InvoiceService();
const agentservice = new AgentsService();
const planservice = new PlanService();
const policyService = new PolicyService();
const tpaservice = new TPAService();
const quotationService = new QuotationService();
const receiptservice = new ReceiptService();

let org$ = organizationservice.getOrganizationTypes();
let ct$ = clienttypeervice.getCleintTypes();
let ts$ = taxservice.getTaxes();
let ps$ = planservice.getPlans();
let tp$ = tpaservice.getTpas();

const useStyles = makeStyles(theme => ({
  input1: {
    width: '50%',
  },
  clientTypeRadioGroup: {
    flexWrap: 'nowrap',
    '& label': {
      flexDirection: 'row',
    },
  },
  formControl: {
    minWidth: '90%',
  },
  inputRoot: {
    '&$disabled': {
      color: 'black',
    },
  },
  disabled: {},
}));

function useQuery1() {
  return new URLSearchParams(useLocation().search);
}

const validationSchema = yup.object({
  tpa: yup.string('Enter TPA value').required('TPA is required field'),
});

export default function PolicyDetailsComponent(props) {
  const classes = useStyles();
  const query = useQuery1();
  const invid = query.get('invid');
  const refid = query.get('refid');
  const { clientDetail } = props;
  const { policyDetails, setPolicyDetails } = props;
  const [organizationTypes, setOrganizationTypes] = React.useState([]);
  const [clientTypes, setClientTypes] = React.useState([]);
  const [groupTypes, setGroupTypes] = React.useState([]);
  const [prefixes, setPrefixes] = React.useState([]);
  const [suffixes, setSuffixes] = React.useState([]);
  const [openRequired, setOpenRequired] = React.useState(false);
  const [idErrorMsg, setIdErrorMsg] = React.useState(false);
  const [checkContact, setCheckContact] = React.useState(false);
  const [disableClientData, setDisableClientData] = React.useState(true);
  const [confirmModal, setConfirmModal] = React.useState(false);
  const { id } = useParams();
  const [agentsList, setAgentsList] = React.useState([]);
  const [openAgentModal, setOpenAgentModal] = React.useState(false);
  const history = useHistory();

  // const [policyDetails, setPolicyDetails] = React.useState(
  //   {
  //     tpa: '',
  //     policyNumber: '',
  //     plan: '',
  //     planData: '',
  //     basePremium: 0,
  //     loadingType: 'PERCENTAGE',
  //     loadingAmount: 0,
  //     loadingValue: 0,
  //     discountType: 'PERCENTAGE',
  //     discountAmount: 0,
  //     discountValue: 0,
  //     totalAmountWithoutTax: 0,
  //     totalAmountWithTax: 0,
  //     totalTaxAmount: 0,
  //     renewalDate: new Date(),
  //     renewalDateVal: '',
  //     invNo: '',
  //     agentsList: [],
  //     taxList: [],
  //   }
  // );

  const formik = useFormik({
    initialValues: {
      tpa: '',
      policyNumber: '',
      plan: '',
      planData: '',
      basePremium: 0,
      loadingType: 'PERCENTAGE',
      loadingAmount: 0,
      loadingValue: 0,
      discountType: 'PERCENTAGE',
      discountAmount: 0,
      discountValue: 0,
      totalAmountWithoutTax: 0,
      totalAmountWithTax: 0,
      totalTaxAmount: 0,
      renewalDate: new Date(),
      renewalDateVal: '',
      invNo: '',
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      if (!formik.values.tpa) {
        formik.setErrors({ tpa: 'Tpa is required' });
        return;
      }

      handleSubmit();
    },
  });

  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [disableOptions, setDisableOptions] = React.useState(true);
  const [taxList, setTaxList] = React.useState([]);
  const [taxList2, setTaxList2] = React.useState([]);

  const [planList, setPlanList] = React.useState([]);
  const [tpaList, setTpaList] = React.useState([]);
  const [selectedIndex, setSelectedIndex] = React.useState('');
  const [selectedAgentsList, setSelectedAgentsList] = React.useState([]);
  const [policyStartDate, setPolicyStartDate] = React.useState(new Date());
  const [policyEndDate, setPolicyEndDate] = React.useState(new Date());

  // const handleStartDateChange = (date) => {
  //   populatePolicyDates(date,policyDetails);
  // };

  const populatePolicyDates = (policyStartDate, policyEndDate, pd) => {
    // var endDate=new Date(policyStartDate.getTime());
    // endDate.setTime(endDate.getTime() + (pd.policyDuration* 24 * 60 * 60 * 1000));

    var renewalDate = new Date(policyEndDate.getTime());
    renewalDate.setTime(renewalDate.getTime() + 24 * 60 * 60 * 1000);

    setPolicyStartDate(policyStartDate);
    setPolicyEndDate(policyEndDate);
    setPolicyDetails({ ...pd, renewalDate });
  };
  const handleEndDateChange = date => {
    setPolicyEndDate(date);
  };
  const useObservable = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  const useObservable2 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            ele['checked'] = false;
            ele['taxVal'] = 0;
          });

          result.content.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          });
        }

        let p = { ...policyDetails, taxList: result.content };
        setPolicyDetails(p);

        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };
  const useObservable3 = (observable, setter) => {
    useEffect(() => {
      let subscription = observable.subscribe(result => {
        if (result.content && result.content.length > 0) {
          result.content.forEach(ele => {
            ele['checked'] = false;
            ele['taxVal'] = 0;
          });

          result.content.sort((a, b) => {
            return a.sortOrder - b.sortOrder;
          });
        }

        setter(result.content);
      });
      return () => subscription.unsubscribe();
    }, [observable, setter]);
  };

  useObservable2(ts$, setTaxList);
  useObservable3(ps$, setPlanList);
  useObservable3(ts$, setTaxList2);

  useObservable(org$, setOrganizationTypes);
  useObservable(ct$, setClientTypes);
  useObservable(tp$, setTpaList);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  React.useEffect(() => {
    if (query.get('invid')) {
      populateFromInvoice(query.get('invid'));
      setDisableOptions(true);
    }
  }, [query.get('invid')]);

  React.useEffect(() => {
    if (query.get('recid')) {
      populateFromReceipt();
      setDisableOptions(true);
    }
  }, [query.get('recid')]);

  React.useEffect(() => {
    if (query.get('refid')) {
      setDisableOptions(false);
      if (id) {
        populatePolicyDetails();
      }
    }
  }, [query.get('refid')]);

  const handleChange = event => {
    const { name, value } = event.target;

    setPolicyDetails({
      ...policyDetails,
      [name]: value,
    });
  };

  const populateFromReceipt = () => {
    if (query.get('recid')) {
      receiptservice.getReceiptDetails(query.get('recid')).subscribe(res => {
        let pageRequest = {
          page: 0,
          size: 1000,
          summary: true,
          active: true,
        };
        pageRequest['invoiceNumber'] = res.invoiceNo;
        invoiceservice.getInvoice(pageRequest).subscribe(ele => {
          if (ele.content.length !== 0) {
            populateFromInvoice(ele.content[0].id);
          }
        });
      });
    }
  };

  const populatePolicyDetails = () => {
    policyService.getPolicyDetails(id).subscribe(values => {
      let pd = [];
      if (values.policyDetails.length !== 0 && values.policyDetails) {
        values.policyDetails.forEach(pdetails => {
          pd.push({
            tpa: pdetails.tpa ? pdetails.tpa : '',
            policyNumber: pdetails.policyNo ? pdetails.policyNo : '',
            plan: pdetails.planName ? pdetails.planName : '',
            planData: '',
            basePremium: pdetails.totalBeforeDiscountAndLoadingAmount ? pdetails.totalBeforeDiscountAndLoadingAmount : 0,
            loadingType: 'PERCENTAGE',
            loadingAmount: pdetails.totalLoading ? pdetails.totalLoading : 0,
            loadingValue: pdetails.loadingEnterValue ? pdetails.loadingEnterValue : 0,
            discountType: 'PERCENTAGE',
            discountAmount: pdetails.totalDiscount ? pdetails.totalDiscount : 0,
            discountValue: pdetails.discountEnterValue ? pdetails.discountEnterValue : 0,
            totalAmountWithoutTax: pdetails.totalAfterDiscountAndLoadingAmount
              ? pdetails.totalAfterDiscountAndLoadingAmount
              : 0,
            totalAmountWithTax: pdetails.totalAmountWithTax ? pdetails.totalAmountWithTax : 0,
            totalTaxAmount: pdetails.totalTaxAmount ? pdetails.totalTaxAmount : 0,
            renewalDate: pdetails.rennewalDate ? new Date(pdetails.rennewalDate) : new Date(),
            renewalDateVal: pdetails.rennewalDate ? pdetails.rennewalDate : '',
            invNo: '',
            proposerAgents: pdetails.proposerAgents,
            proposerTax: pdetails.proposerTax,
            agentsList: [],
            taxList: [],
            anniversary: pdetails.anniversary ? pdetails.anniversary : '',
            policyId: pdetails.id ? pdetails.id : '',
          });
        });
        // setPolicyDetails(pd)

        const frkPlan = pd.map((el, i) => {
          return planservice.getPlanDetails(el.plan);
        });

        forkJoin(frkPlan).subscribe(res => {
          res.forEach((pdata, i) => {
            pd[i].planData = pdata;
          });

          const policies$ = pd.map(p => {
            return getZipped(p);
          });

          zip(policies$).subscribe(res => {
            res.forEach((policy, i) => {
              policy.agents.forEach((pa, j) => {
                pd[i].agentsList.push({
                  agentId: pd[i].proposerAgents[j].agentId,
                  commissionType: pd[i].proposerAgents[j].commissionType,
                  commissionValue: pd[i].proposerAgents[j].commissionValue,
                  finalValue: pd[i].proposerAgents[j].finalValue,
                  name: pa.agentBasicDetails?.name,
                  code: pa.agentBasicDetails?.code,
                  contactNo: pa.agentBasicDetails?.contactNos[0].contactNo,
                });
              });

              policy.taxArr.content.forEach(ta => {
                ta['checked'] = false;
                ta['taxVal'] = 0;
                pd[i].proposerTax.forEach(pt => {
                  if (pt.taxId === ta.id) {
                    ta['checked'] = true;
                    ta['taxVal'] = pt.taxAmount;
                  }
                });
              });

              pd[i].taxList = policy.taxArr.content;
            });
            setPolicyDetails(pd);
          });
        });
      }
    });
  };

  const handleTaxCalc = pd => {
    pd.forEach((el, i) => {
      planservice.getPlanDetails(el.plan).subscribe(pl => {
        el.planData = pl;
        setPolicyDetails(pd);
      });
    });
  };

  const getZipped = policyDetail => {
    let agents$ = zip(
      policyDetail.proposerAgents.map(pa => {
        return agentservice.getAgentDetails(pa.agentId);
      }),
    );
    let taxes$ = zip(
      policyDetail.proposerTax.map(pt => {
        return taxservice.getTaxDetails(pt.taxId);
      }),
    );
    let taxList$ = taxservice.getTaxes();
    return forkJoin({ policyDetail: of(policyDetail), agents: agents$, taxes: taxes$, taxArr: taxList$ });
  };

  const populateFromInvoice = invid => {
    invoiceservice
      .getInvoiceDetails(invid)

      .subscribe(invoiceDetail => {
        let obj = {
          plan: invoiceDetail.planId,
          planData: '',
          basePremium: invoiceDetail.totalBeforeDiscountAndLoadingAmount,
          loadingType: 'PERCENTAGE',
          loadingAmount: invoiceDetail.totalLoading,
          loadingValue: invoiceDetail.loadingEnterValue,
          discountType: 'PERCENTAGE',
          discountAmount: invoiceDetail.totalDiscount,
          discountValue: invoiceDetail.discountEnterValue,
          totalAmountWithoutTax: invoiceDetail.totalAfterDiscountAndLoadingAmount,
          totalAmountWithTax: invoiceDetail.totalAmountWithTax,
          totalTaxAmount: invoiceDetail.totalTaxAmount,
          renewalDate: invoiceDetail.invoiceDate ? new Date(invoiceDetail.invoiceDate) : new Date(),
          renewalDateVal: invoiceDetail.invoiceDate ? invoiceDetail.invoiceDate : '',
          invNo: invoiceDetail.invoiceNumber,
          // proposerAgents: pdetails.proposerAgents,
          // proposerTax: pdetails.proposerTax,
          agentsList: [],
          taxList: [],
          policyDuration: 0,
        };

        let agents$;
        if (invoiceDetail.invoiceAgents.length > 0) {
          agents$ = zip(
            invoiceDetail.invoiceAgents.map(pa => {
              return agentservice.getAgentDetails(pa.agentId);
            })
          );
        } else {
          agents$ = of([]); // Create an observable that emits an empty array
        }

        let quotationDetails$ = quotationService.getQuoationDetailsByID(invoiceDetail.quotationId);

        if (id) {
          forkJoin({
            policyDetail: policyService.getPolicyDetails(id),
            planDatas: planservice.getPlanDetails(invoiceDetail.planId),
            taxes: taxservice.getTaxes(),
            agents: agents$,
            quotation: quotationDetails$,
          }).subscribe(res => {
            obj['planData'] = res.planDatas;

            if (res.policyDetail) {
              obj['tpa'] = res.policyDetail.tpa;
              formik.setFieldValue('tpa', res.policyDetail.tpa);
              obj['policyId'] = res.policyDetail.id;
              // obj['anniversary'] = res.policyDetail.policyDetails[0].anniversary;
              obj['policyNumber'] = res.policyDetail.policyNumber;
              // obj.policyDuration= res.quotation['policyDuration'];
            }

            if (res.taxes.content && res.taxes.content.length > 0) {
              res.taxes.content.forEach(re => {
                re['checked'] = false;
                re['taxVal'] = 0;
              });

              invoiceDetail.invoiceTaxes.forEach(inv => {
                res.taxes.content.forEach(tx => {
                  if (tx.id === inv.taxId) {
                    tx['checked'] = true;
                    tx['taxVal'] = inv.taxAmount;
                  }
                });
              });
              res.taxes.content.sort((a, b) => {
                return a.sortOrder - b.sortOrder;
              });
              obj['taxList'] = res.taxes.content;
            }
            let agntList = [];
            res.agents.forEach((ag, i) => {
              agntList.push({
                agentId: invoiceDetail.invoiceAgents[i].agentId,
                commissionType: invoiceDetail.invoiceAgents[i].commissionType,
                commissionValue: invoiceDetail.invoiceAgents[i].commissionValue,
                finalValue: invoiceDetail.invoiceAgents[i].finalValue,
                name: ag.agentBasicDetails?.name,
                code: ag.agentBasicDetails?.code,
                contactNo: ag.agentBasicDetails?.contactNos[0].contactNo,
              });
            });
            obj['agentsList'] = agntList;

            setPolicyDetails(obj);
          });
        } else {
          let proposerID = localStorage.getItem('proposerid');
          forkJoin({
            planDatas: planservice.getPlanDetails(invoiceDetail.planId),
            taxes: taxservice.getTaxes(),
            agents: agents$,
            quotation: quotationDetails$,
          }).subscribe(res => {
            obj['planData'] = res.planDatas;
            obj['tpa'] = '';
            obj['policyId'] = '';
            obj['anniversary'] = '';
            obj['policyNumber'] = '';
            obj.policyDuration = res.quotation['policyDuration'];

            if (res.taxes.content && res.taxes.content.length > 0) {
              res.taxes.content.forEach(re => {
                re['checked'] = false;
                re['taxVal'] = 0;
              });

              invoiceDetail.invoiceTaxes.forEach(inv => {
                res.taxes.content.forEach(tx => {
                  if (tx.id === inv.taxId) {
                    tx['checked'] = true;
                    tx['taxVal'] = inv.taxAmount;
                  }
                });
              });
              res.taxes.content.sort((a, b) => {
                return a.sortOrder - b.sortOrder;
              });
              obj['taxList'] = res.taxes.content;
            }
            let agntList = [];
            res.agents.forEach((ag, i) => {
              agntList.push({
                agentId: invoiceDetail.invoiceAgents[i].agentId,
                commissionType: invoiceDetail.invoiceAgents[i].commissionType,
                commissionValue: invoiceDetail.invoiceAgents[i].commissionValue,
                finalValue: invoiceDetail.invoiceAgents[i].finalValue,
                name: ag.agentBasicDetails?.name,
                code: ag.agentBasicDetails?.code,
                contactNo: ag.agentBasicDetails?.contactNos[0].contactNo,
              });
            });
            obj['agentsList'] = agntList;

            let sd = new Date(res.quotation['policyStartDate']);
            let ed = new Date(res.quotation['policyEndDate']);

            populatePolicyDates(sd, ed, obj);
          });
        }
      });
  };

  const handleFieldChecked = (e, j) => {
    const { name, checked } = e.target;
    const p = { ...policyDetails };
    p.taxList[j][name] = checked;
    setPolicyDetails(p);
    // setTaxList(list);
    calculateTax(p.taxList, policyDetails.totalAmountWithoutTax);
  };

  const openConfirmationModal = () => {
    setConfirmModal(true);
  };

  const closeConfirmationModal = val => {
    if (val === 'yes') {
      setDisableClientData(false);
    }
    setConfirmModal(false);
  };
  const handlePlanChange = (e, value) => {
    const p = { ...policyDetails };
    p['planData'] = value;
    p['plan'] = value.id;

    setPolicyDetails(p);
  };

  const handleSubmit = () => {
    let pload = {
      clientId: query.get('clientid'),
      policyStartDate: new Date(policyStartDate).getTime(),
      policyEndDate: new Date(policyEndDate).getTime(),
      tpa: formik.values.tpa,
    };
    if (query.get('invid')) {
      pload['sourceId'] = query.get('invid');
      pload['sourceType'] = 'INVOICE';
    } else if (query.get('recid')) {
      pload['sourceId'] = query.get('recid');
      pload['sourceType'] = 'RECEIPT';
    }

    if (query.get('mode') === 'create') {
      let proposerID = localStorage.getItem('proposerid');

      policyService.savePolicy(pload).subscribe(ele => {
        localStorage.removeItem('proposerid');
        history.push(`/policies?mode=viewList`);
        // window.location.reload();
      });
    } else if (query.get('mode') === 'edit') {
      if (id) {
        policyService.editPolicy(pload, id, '3').subscribe(ele => {
          localStorage.removeItem('proposerid');
          history.push(`/policies?mode=viewList`);
          // window.location.reload();
        });
      }
    }
  };

  const handleOpenAgentModal = () => {
    setSelectedAgentsList(policyDetails.agentsList);
    //setSelectedIndex(i)
    setOpenAgentModal(true);
  };
  const handleCloseAgentModal = () => {
    setOpenAgentModal(false);
    //setSelectedIndex('');
  };

  const handleAgentModalSubmit = selectedAgents => {
    const p = { ...policyDetails };
    p.agentsList = selectedAgents;
    setPolicyDetails(p);
    setOpenAgentModal(false);
  };

  const handleDateChange = date => {
    const p = { ...policyDetails };

    const timestamp = new Date(date).getTime();
    // formik.setFieldValue('renewalDateVal', timestamp);
    // formik.setFieldValue('renewalDate', date);
    p['renewalDateVal'] = timestamp;
    p['renewalDate'] = date;
    setPolicyDetails(p);
  };
  const changeCommision = (e, j) => {
    const { name, value } = e.target;
    const p = { ...policyDetails };
    p.agentsList[j][name] = value;
    setPolicyDetails(p);
    calculateFinalValue(p, j);
  };

  const calculateFinalValue = (changedPolicyDetails, j) => {
    //
    changedPolicyDetails.agentsList[j]['finalValue'] =
      (Number(changedPolicyDetails.agentsList[j]['commissionValue']) * Number(changedPolicyDetails.totalAmountWithoutTax)) /
      100;
    setPolicyDetails(changedPolicyDetails);
  };

  const calculateAgentValues = totalAmountWithoutTax => {
    const p = { ...policyDetails };
    p.agentsList.forEach(ag => {
      ag.finalValue = (Number(ag['commissionValue']) * Number(totalAmountWithoutTax)) / 100;
    });
    setPolicyDetails(p);
  };

  // const calculateAgentValues = totalAmountWithoutTax => {
  //   const list = [...agentsList];
  //   list.forEach(ele => {
  //     ele['finalValue'] = (Number(ele.commissionValue) * Number(totalAmountWithoutTax)) / 100;
  //   });
  //   setAgentsList(list);
  // };

  const handleClose = e => {
    props.handleClose(e);
  };

  const handlePChange = (e, value) => {
    setPolicyDetails({
      ...clientDetail,
      pOrgData: value,
      parentclientId: value.id,
    });
  };

  const handlePremiumChange = e => {
    const { name, value } = e.target;

    const p = { ...policyDetails };
    p[name] = value;
    setPolicyDetails(p);

    let lv = policyDetails.loadingValue;
    let dv = policyDetails.discountValue;
    calculateTotalAmoutWithoutTax(lv, dv, value);
  };

  const handleLoadingChange = e => {
    const { name, value } = e.target;
    const p = { ...policyDetails };
    p['loadingValue'] = value;
    setPolicyDetails(p);
    // formik.setFieldValue('loadingValue', value);

    // let lv = formik.values.loadingValue;
    let dv = policyDetails.discountValue;
    let tpa = policyDetails.basePremium;
    calculateTotalAmoutWithoutTax(value, dv, tpa);
  };
  const handleDiscountChange = e => {
    const { name, value } = e.target;
    const p = { ...policyDetails };
    p['discountValue'] = value;
    setPolicyDetails(p);

    let lv = policyDetails.loadingValue;
    // let dv = formik.values.discountValue;
    let tpa = policyDetails.basePremium;
    calculateTotalAmoutWithoutTax(lv, value, tpa);
  };

  const calculateTotalAmoutWithoutTax = (loadingVal, discountVal, premiumTotal) => {
    let la = (Number(loadingVal) / 100) * Number(premiumTotal);
    let da = (Number(discountVal) / 100) * Number(premiumTotal);

    const p = { ...policyDetails };
    p['discountAmount'] = da;
    p['loadingAmount'] = la;

    // formik.setFieldValue('discountAmount', da);
    // formik.setFieldValue('loadingAmount', la);

    let at = Number(premiumTotal) + la - da;
    // formik.setFieldValue('totalAmountWithoutTax', at);
    p['totalAmountWithoutTax'] = at;

    setPolicyDetails(p);
    calculateAgentValues(at);
    calculateTax(taxList, at);
  };

  const calculateTax = (txlist, totalAmountWithoutTax) => {
    txlist.forEach(ele => {
      if (ele.checked) {
        if (ele.type === 'PERCENTAGE') {
          ele.taxVal = (Number(ele.value) * Number(totalAmountWithoutTax)) / 100;
        }
        if (ele.type === 'FIXED') {
          ele.taxVal = Number(ele.value);
        }
      }
    });
    setTaxList(txlist);
    let grandTotal = Number(totalAmountWithoutTax);
    let tt = 0;
    txlist.forEach(v => {
      if (v.checked) {
        grandTotal = grandTotal + Number(v.taxVal);
        tt = tt + Number(v.taxVal);
      }
    });

    // formik.setFieldValue('totalAmountWithTax', grandTotal);
    // formik.setFieldValue('totalTaxAmount', tt);

    const p = { ...policyDetails };
    p['totalAmountWithTax'] = grandTotal;
    p['totalTaxAmount'] = tt;
    setPolicyDetails(p);
  };

  const handleSnackClose = (event, reason) => {
    setOpenRequired(false);
  };
  const handleIDErrorClose = (event, reason) => {
    setIdErrorMsg(false);
  };
  const handleContactCheckClose = (event, reason) => {
    setCheckContact(false);
  };

  const handleAddPolicyDetails = () => {
    taxservice.getTaxes().subscribe(result => {
      if (result.content && result.content.length > 0) {
        result.content.forEach(ele => {
          ele['checked'] = false;
          ele['taxVal'] = 0;
        });

        result.content.sort((a, b) => {
          return a.sortOrder - b.sortOrder;
        });
      }
      setPolicyDetails([
        ...policyDetails,
        {
          tpa: '',
          policyNumber: '',
          plan: '',
          planData: '',
          basePremium: 0,
          loadingType: 'PERCENTAGE',
          loadingAmount: 0,
          loadingValue: 0,
          discountType: 'PERCENTAGE',
          discountAmount: 0,
          discountValue: 0,
          totalAmountWithoutTax: 0,
          totalAmountWithTax: 0,
          totalTaxAmount: 0,
          renewalDate: new Date(),
          renewalDateVal: '',
          invNo: '',
          agentsList: [],
          taxList: result.content,
        },
      ]);
    });
  };

  const handleChangePolicyDetails = (e, i) => {
    const { name, value } = e.target;
    const p = { ...policyDetails };
    p[name] = value;
    setPolicyDetails(p);
  };

  return (
    <form onSubmit={formik.handleSubmit} noValidate>
      <Paper elevation="none">
        <Box p={3} my={2}>
          <Snackbar open={openRequired} autoHideDuration={6000} onClose={handleSnackClose}>
            <Alert onClose={handleSnackClose} severity="error">
              Please fill up all required fields marked with *
            </Alert>
          </Snackbar>
          <Snackbar open={checkContact} autoHideDuration={6000} onClose={handleContactCheckClose}>
            <Alert onClose={handleContactCheckClose} severity="error">
              Please enter valid contact number/Email id
            </Alert>
          </Snackbar>
          <Snackbar open={idErrorMsg} autoHideDuration={6000} onClose={handleIDErrorClose}>
            <Alert onClose={handleIDErrorClose} severity="error">
              Please provide both Identification Type and Identification Number.
            </Alert>
          </Snackbar>
          {/* <form onSubmit={handleSubmit}> */}
          {
            <>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={4}>
                  <FormControl
                    className={classes.formControl}
                    required
                    error={formik.touched.tpa && Boolean(formik.errors.tpa)}>
                    <InputLabel id="demo-simple-select-label" style={{ marginBottom: '0px' }}>
                      TPA
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      name="tpa"
                      // disabled={disableOptions}
                      value={formik.values.tpa}
                      onChange={formik.handleChange}>
                      {tpaList.map(ele => {
                        return (
                          <MenuItem key={ele.code} value={ele.code}>
                            {ele.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                    {formik.touched.tpa && Boolean(formik.errors.tpa) && (
                      <FormHelperText>{formik.touched.tpa && formik.errors.tpa}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <Grid container justify="space-around"> */}
                    <KeyboardDatePicker
                      views={['year', 'month', 'date']}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      autoOk={true}
                      id="date-picker-inline"
                      label="Policy Start Date"
                      value={policyStartDate}
                      disabled={true}
                      //onChange={handleStartDateChange}
                      KeyboardButtonProps={{
                        'aria-label': 'change ing date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <Grid container justify="space-around"> */}
                    <KeyboardDatePicker
                      views={['year', 'month', 'date']}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      autoOk={true}
                      id="date-picker-inline"
                      label="Policy End Date"
                      value={policyEndDate}
                      onChange={handleEndDateChange}
                      disabled={true}
                      KeyboardButtonProps={{
                        'aria-label': 'change ing date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
                {query.get('refid') ? (
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="referenceNo"
                      disabled={true}
                      value={query.get('refid')}
                      label="Reference Number"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </Grid>
                ) : (
                  <Grid item xs={4}>
                    <TextField
                      size="small"
                      id="standard-basic"
                      name="invoiceNumber"
                      disabled={true}
                      value={policyDetails.invNo}
                      label="Invoice Number"
                      InputProps={{
                        classes: {
                          root: classes.inputRoot,
                          disabled: classes.disabled,
                        },
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="policyNumber"
                    disabled={true}
                    value={policyDetails.policyNumber}
                    label="Policy Number"
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={4}>
                  {/* <TextField size="small"
                                id="standard-basic"
                                name="plan"
                                disabled={true}
                                value={formik.values.plan}
                                onChange={formik.handleChange}
                                label="Plan"
                                InputProps={{
                                    classes: {
                                        root: classes.inputRoot,
                                        disabled: classes.disabled
                                    }
                                }}
                            /> */}
                  <Autocomplete
                    id="combo-box-demo"
                    options={planList}
                    getOptionLabel={option => option.name}
                    value={policyDetails.planData}
                    style={{ width: '50%' }}
                    disabled={disableOptions}
                    renderInput={params => <TextField {...params} label="Plan" />}
                    name="planData"
                    onChange={(e, value) => handlePlanChange(e, value)}
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    id="standard-basic"
                    name="basePremium"
                    type="number"
                    disabled={disableOptions}
                    value={policyDetails.basePremium}
                    onChange={e => handlePremiumChange(e)}
                    label="Base Premium"
                    InputProps={{
                      classes: {
                        root: classes.inputRoot,
                        disabled: classes.disabled,
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={4}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/* <Grid container justify="space-around"> */}
                    <KeyboardDatePicker
                      views={['year', 'month', 'date']}
                      variant="inline"
                      format="dd/MM/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      disabled={disableOptions}
                      readOnly={true}
                      label="Renewal Date"
                      value={policyDetails.renewalDate}
                      //onChange={(date) => handleDateChange(date)}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}></Grid>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <table style={{ width: '100%' }}>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid item xs={4}>
                        <th />
                      </Grid>
                    </tr>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={3}>
                        <td>Discount</td>
                      </Grid>

                      <Grid item xs={6}>
                        <td>
                          <TextField
                            size="small"
                            type="number"
                            id="standard-basic"
                            name="discountValue"
                            value={policyDetails.discountValue}
                            disabled={disableOptions}
                            onChange={e => handleDiscountChange(e)}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                            label="discount percentage (%)"
                          />
                        </td>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <td>{policyDetails.discountAmount?.toFixed(2)}</td>
                      </Grid>
                    </tr>
                    <tr style={{ display: 'flex', flexDirection: 'row' }}>
                      <Grid style={{ display: 'flex', alignItems: 'center' }} item xs={3}>
                        <td>Loading</td>
                      </Grid>
                      <Grid item xs={6}>
                        <td>
                          <TextField
                            size="small"
                            type="number"
                            id="standard-basic"
                            name="loadingValue"
                            value={policyDetails.loadingValue}
                            disabled={disableOptions}
                            onChange={e => handleLoadingChange(e)}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                            label="loading percentage (%)"
                          />
                        </td>
                      </Grid>
                      <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <td>{policyDetails.loadingAmount?.toFixed(2)}</td>
                      </Grid>
                    </tr>
                  </table>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={6}>
                  <FormGroup row>
                    {policyDetails.taxList?.map((row, j) => (
                      <FormControlLabel
                        key={j}
                        control={
                          <Checkbox
                            checked={row.checked}
                            onChange={e => handleFieldChecked(e, j)}
                            name="checked"
                            color="primary"
                            disabled={disableOptions}
                            InputProps={{
                              classes: {
                                root: classes.inputRoot,
                                disabled: classes.disabled,
                              },
                            }}
                          />
                        }
                        label={row.name}
                      />
                    ))}
                  </FormGroup>
                </Grid>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>
                    Total After discount and loading :{policyDetails.totalAmountWithoutTax?.toFixed(2)}
                  </span>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Tax name</TableCell>
                          <TableCell>Tax value</TableCell>
                          <TableCell>Tax type</TableCell>
                          <TableCell align="right">Tax Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {policyDetails.taxList?.map((row, i) => {
                          return (
                            row.checked && (
                              <TableRow key={row.id}>
                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.value}</TableCell>
                                <TableCell>{row.type}</TableCell>
                                <TableCell align="right">{row.taxVal.toFixed(2)}</TableCell>
                              </TableRow>
                            )
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <span style={{ fontWeight: 'bold' }}>Grand Total :{policyDetails.totalAmountWithTax?.toFixed(2)}</span>
                </Grid>
              </Grid>
              <Grid container spacing={3} style={{ marginBottom: '20px' }}>
                <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {/* {query2.get("mode") !== 'view' ? <Button variant="contained" color="primary" onClick={handleOpenAgentModal}>
                                        Search Agent
                                     </Button> : null} */}
                  {!disableOptions && (
                    <Button variant="contained" color="primary" onClick={() => handleOpenAgentModal()}>
                      Search Agent
                    </Button>
                  )}

                  <ProposerAgentModal
                    agentsList={selectedAgentsList}
                    handleCloseAgentModal={handleCloseAgentModal}
                    openAgentModal={openAgentModal}
                    setAgentsList={setAgentsList}
                    handleAgentModalSubmit={handleAgentModalSubmit}
                  />
                </Grid>

                <Grid item xs={12} style={{ marginTop: '10px' }}>
                  <TableContainer component={Paper}>
                    <Table size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell>Agent name</TableCell>
                          <TableCell>Commission value</TableCell>
                          <TableCell align="right">Final value</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {policyDetails.agentsList?.map((row, j) => (
                          <TableRow key={row.agentId}>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>
                              <TextField
                                size="small"
                                type="number"
                                id="standard-basic"
                                name="commissionValue"
                                value={row.commissionValue}
                                disabled={disableOptions}
                                onChange={e => {
                                  changeCommision(e, j);
                                }}
                                label="Commission value (%)"
                                InputProps={{
                                  classes: {
                                    root: classes.inputRoot,
                                    disabled: classes.disabled,
                                  },
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">{Number(row.finalValue).toFixed(2)}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </>
          }

          <Grid container spacing={3} style={{ marginBottom: '20px' }}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              {/* {!disableClientData && */}

              <Button variant="contained" color="primary" style={{ marginRight: '5px' }} type="submit">
                Save and Finish
              </Button>
              {/* } */}
              {/* {disableClientData &&
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={openConfirmationModal}
                            >
                                Edit Details
                            </Button>} */}
            </Grid>
            <Grid item xs={12}>
              <EditConfirmationModal confirmModal={confirmModal} closeConfirmationModal={closeConfirmationModal} />
            </Grid>
          </Grid>
          {/* </form> */}
        </Box>
      </Paper>
    </form>
  );
}
