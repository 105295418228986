import React from 'react';
import { useHistory } from 'react-router-dom';
import { map, switchMap } from 'rxjs/operators';
import RoleService from '../../../../services/utility/role';
import { ClientTypeService, PlanService } from '../../../remote-api/fettle-remote-api';
import { FettleDataGrid } from '../../../shared-components';
import { LetterService } from '../../../remote-api/api/claims-services';

const PAGE_NAME = 'CLAIM';
const roleService = new RoleService();
const clienttypeervice = new ClientTypeService();
const planservice = new PlanService();
const letterService = new LetterService();

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
  },
) => {
  pageRequest.sort = ['rowLastUpdatedDate dsc'];
  if (pageRequest.searchKey) {
    pageRequest['letterType'] = pageRequest.searchKey.trim();
    pageRequest['formatName'] = pageRequest.searchKey.trim();
  }
  delete pageRequest.searchKey;
  return letterService.getLetters(pageRequest).pipe(
    map(data => {
          return data;
        }))
};

const columnsDefinations = [
  {
    field: 'serial',
    headerName: 'SL#',
    body: (rowData, data) => data.rowIndex + 1,
    style: { width: '2rem' }
  },
  { field: 'formatName', headerName: 'Format Name', style: { width: '4rem' } },
  { field: 'letterType', headerName: 'Letter type', style: { width: '4rem' } },
];

export default function LetterListComponent(props) {
  const history = useHistory();
  const [reloadTable, setReloadTable] = React.useState(false);

  const handleOpen = () => {
    history.push('/claims/letter?mode=create');
  };

  const openEditSection = plan => {
    history.push(`/claims/letter/${plan.id}?mode=edit`);
  };

  const actionBtnList = [
    {
      key: 'update_plan',
      icon: 'pi pi-user-edit',
      className: 'ui-button-warning',
      onClick: openEditSection,
    }
  ];
  const xlsColumns = [
    'serial',
    'formatName',
    'letterType'
  ];
  
  const configuration = {
    enableSelection: false,
    scrollHeight: '300px',
    pageSize: 10,
    actionButtons: roleService.checkActionPermission(PAGE_NAME, '', () => { }, actionBtnList),
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      onCreateButtonClick: handleOpen,
      text: 'Letter Management',
      enableGlobalSearch: true,
      searchText: 'Search by format name, letter type',
      //   onSelectionChange: handleSelectedRows,
      //   selectionMenus: [{ icon: "", text: "Blacklist", disabled: selectionBlacklistMenuDisabled, onClick: openBlacklist }],
      //   selectionMenuButtonText: "Action"
    },
  };

  return (
    <div>
      <FettleDataGrid
        $dataSource={dataSource$}
        config={configuration}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
        reloadTable={reloadTable}
      />
    </div >
  );
}
