import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
// import { DateFnsAdapter } from '@date-io/date-fns';

import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import DescriptionIcon from '@material-ui/icons/Description';

import moment from 'moment';

 // const STATIC_TIMELINE = [
    //     {
    //         timestamp: new Date(2021, 2, 15, 18, 35),
    //         title: 'Enhancement Approved',
    //         description: '',
    //     },
    //     {
    //         timestamp: new Date(2021, 2, 15, 18, 28),
    //         title: 'Enhancement Requested',
    //         description: '',
    //     },
    //     {
    //         timestamp: new Date(2021, 2, 15, 18, 18),
    //         title: 'Pre-auth Approve',
    //         description: '',
    //     },
    //     {
    //         timestamp: new Date(2021, 2, 15, 17, 45),
    //         title: 'Pre-auth Request',
    //         description: '',
    //     },
    // ];

export const FettleDocTimeline = ({ timeline = [] }) => {
  const uiTimeline = useMemo(() => {
    return timeline.map((it, index) => {
      return {
        ...it,
        key: index,
        // timestamp: DateFnsAdapter.format(it.timestamp, 'fullDateTime12h'),
        timestamp: moment(it.timestamp).format('DD MMM YY hh:mm A'),
      };
    });
  }, [timeline]);
  return (
    <Timeline>
      {uiTimeline.map(it => {
        return (
          <TimelineItem>
            <TimelineSeparator>
              <TimelineDot />
              <TimelineConnector />
            </TimelineSeparator>
            <TimelineContent>
              <div>
                <div>{it.timestamp}</div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginTop: '12px', marginRight: '12px' }}>
                    <DescriptionIcon />
                  </div>
                  <div style={{ marginTop: '8px', marginBottom: '18px' }}>
                    <div style={{ marginBottom: '8px' }}>{it.title}</div>
                    <div>{it.description}</div>
                  </div>
                </div>
              </div>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

FettleDocTimeline.propTypes = {
  timeline: PropTypes.arrayOf({
    timestamp: PropTypes.instanceOf(Date).isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
};
