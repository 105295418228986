import { makeStyles, TextField } from '@material-ui/core';
import { Button } from 'primereact/button';
import Grid from '@material-ui/core/Grid';
import React, { Fragment, useState } from 'react';
import { FettleDataGrid } from './fettle.data.grid';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 140,
    width: 100,
  },
  control: {
    padding: theme.spacing(2),
  },
}));

export const FettleMultiFieldSearch = props => {
  const [tempValus, setTempValues] = useState(null);

  const classes = useStyles();
  const [showDataGrid, setShowDataGrid] = useState(false);
  const [dataGridKey, setDataGridKey] = useState(1);
  // const [fields, setFields] = useState();

  const configuration = {
    enableSelection: false,
    scrollHeight: props.dataGridScrollHeight,
    actionButtons: [
      { icon: 'pi pi-clone', className: 'ui-button-warning', onClick: item => props.onSelect && props.onSelect(item) },
    ],
    header: {
      enable: false,
    },
    pageSize: props.dataGridPageSize,
    disableConfirm: true,
  };

  const proxyDataSource$ = pageRequest => {
    return props.$dataSource({ ...tempValus }, pageRequest);
  };

  const handleOnSearchButtonClick = e => {
    const v = props.fields
      .map(field => [field.propertyName, field.value])
      .reduce((obj, item) => {
        obj[item[0]] = item[1];
        return obj;
      }, {});
    setTempValues(v);
    setShowDataGrid(true);
    setDataGridKey(dataGridKey + 1);
  };

  const buildField = field => {
    switch (field.type) {
      case 'text':
      default:
        return (
          <TextField label={field.label} fullWidth key={field.propertyName} onChange={e => (field.value = e.target.value)} />
        );
    }
  };

  const buildFieldGrid = f => {
    return (
      <Grid style={{ minWidth: 130 }} item xs key={`grid_${f.propertyName}`}>
        {buildField(f)}
      </Grid>
    );
  };

  const buildFieldsGrid = () => {
    return (
      <Grid container spacing={1}>
        {props.fields
          .map(field => {
            field.type = field.type || 'text';
            field.value = field.value || '';
            return field;
          })
          .map(buildFieldGrid)}
        <Grid item xs container alignItems="center" justifyContent="flex-end">
          <Button variant="contained" color="primary" onClick={handleOnSearchButtonClick}>
            Search
          </Button>
        </Grid>
        {showDataGrid && (
          <Fragment key={dataGridKey}>
            <Grid item xs={12}>
              <FettleDataGrid
                $dataSource={proxyDataSource$}
                columnsDefination={props.columnsDefinations}
                config={configuration}
              />
            </Grid>
          </Fragment>
        )}
      </Grid>
    );
  };

  return buildFieldsGrid();
};
