import { withStyles, makeStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import RoleService from '../../../services/utility/role';
// import { FundService } from '../../../remote-api/api/fund-services';
import { map, switchMap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { FettleDataGrid } from '../../shared-components';
import { RenewalService } from '../../remote-api/api/renewal-services';
import { ContactSupportOutlined } from '@material-ui/icons';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ClientService, PolicyService } from '../../remote-api/fettle-remote-api';
import { QuotationService } from '../../remote-api/api/quotation-services';

const PAGE_NAME = 'PLAN';
const roleService = new RoleService();
const renewalService = new RenewalService();
const policyService = new PolicyService();
const quotationService = new QuotationService();
const clientService = new ClientService();
// const fundService = new FundService();
const sampleData = [
  {
    id: '1001',
    policyNo: '001203100012',
    corporateName: 'TCS',
    accounHandler: 'Unknown',
    startDtate: '28/12/2020',
    endDate: '28/12/2022',
    transactionCurrency: '$',
  },
  {
    id: '1002',
    policyNo: '001231004587',
    corporateName: 'IBM',
    accounHandler: 'Unknown',
    startDtate: '28/12/2020',
    endDate: '28/12/2021',
    transactionCurrency: '$',
  },
  {
    id: '1003',
    policyNo: '001231001894',
    corporateName: 'retail_1',
    accounHandler: 'Unknown',
    startDtate: '28/12/2020',
    endDate: '28/12/2021',
    transactionCurrency: '$',
  },
  {
    id: '1004',
    policyNo: '000101233247',
    corporateName: 'retail_2',
    accounHandler: 'Unknown',
    startDtate: '28/12/2020',
    endDate: '28/12/2021',
    transactionCurrency: '$',
  },
  {
    id: '1005',
    policyNo: '001231085497',
    corporateName: 'HCL',
    accounHandler: 'Unknown',
    startDtate: '28/12/2020',
    endDate: '28/12/2021',
    transactionCurrency: '$',
  },
];

const data$ = new Observable(subscriber => {
  subscriber.next(sampleData);
});

const useStyles = makeStyles(theme => ({
  tableBg: {
    height: 400,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    borderRadius: '4px',
  },
  renewalListButton: {
    marginLeft: '5px',
  },
}));

const dataSource$ = (
  pageRequest = {
    page: 0,
    size: 10,
    summary: true,
    active: true,
    availableForRenewal: true,
  },
) => {
  pageRequest.sort = ['rowCreatedDate dsc'];
  // pageRequest.isAvailableForRenewal = [true];

  if (pageRequest.searchKey) {
    pageRequest['code'] = pageRequest.searchKey.trim();
    pageRequest['name'] = pageRequest.searchKey.trim();

    delete pageRequest.active;
  } else {
    pageRequest.summary = true;
    pageRequest.active = true;
  }
  delete pageRequest.searchKey;
  return renewalService.getRenewalPolicy(pageRequest).pipe(
    map(data => {
      let content = data.content;
      let records = content.map(item => {
        item['policyStartDate'] = new Date(item.policyStartDate).toLocaleDateString();
        item['policyEndDate'] = new Date(item.policyEndDate).toLocaleDateString();
        return item;
      });
      data.content = records;
      return data;
    }),
  );
};

const columnsDefinations = [
  { field: 'policyNumber', headerName: 'Policy No' },
  { field: 'corporateName', headerName: 'Corporate Name' },
  { field: 'accounHandler', headerName: 'Account handler' },
  { field: 'policyStartDate', headerName: 'Start date' },
  { field: 'policyEndDate', headerName: 'End date' },
  { field: 'transactionCurrency', headerName: 'Transaction Currency' },
];

const RenewalListComponent = () => {
  const classes = useStyles();
  const history = useHistory();
  const [selectedItems, setSelectedItems] = useState([]);
  const [disableRenew,setDisableRenew] = useState(true);
  const handleOpen = () => {
    history.push('/renewals/pending?mode=create');
  };
  const openEditSection = policy => {
    checkQuotationByPolicy(policy.id);
    // checkQuotationByPolicy(`/renewals/pending/${policy.id}?mode=edit`);   
  };
  const handleSelectedRows = selectedRows => {
    if (selectedRows.length !== 0) {
      setSelectedItems(selectedRows);
      if(selectedRows.length == 1){
        setDisableRenew(false); 
      } else{
        setDisableRenew(true); 
      }
    } else {
      setSelectedItems([]);
      setDisableRenew(true); 
    }
  };
  const checkQuotationByPolicy = (policyId) => {
    let pageRequest = {
      page: 0,
      size: 10,
      summary: true,
      active: true,
      renewalPolicyId:policyId
    }
    quotationService.getQuoationDetails(pageRequest).subscribe(data => {
      if (data.content && data.content.length != 0) {
        let quotationDta = data.content[0]
        if (quotationDta.renewalPolicyId) {
          history.push(`/quotations/${quotationDta.id}?mode=edit&type=renewal`);
        }
        if (!quotationDta.renewalPolicyId) {
          history.push(`/quotations/?mode=create&type=renewal&policyId=` + policyId);
        }
      }
      if (data.content && data.content.length == 0) {
        history.push(`/quotations/?mode=create&type=renewal&policyId=` + policyId);
      }
    });
  }
  const handleRenew = () => {
    if (selectedItems.length !== 0) {
      let policyId = selectedItems[0].id;
      checkQuotationByPolicy(policyId)
    }
  };
  const selectableRow = e => {
    return true;
  };

  const xlsColumns = [
    'policyNumber',
    'corporateName',
    'accounHandler',
    'policyStartDate',
    'policyEndDate',
    'transactionCurrency'
  ];
  
  const configuration = {
    enableSelection: true,
    scrollHeight: '300px',
    isRowSelectable: selectableRow,
    pageSize: 10,
    actionButtons: [
      {
        label: 'RENEW',
        variant: 'text',
        className: 'ui-button-warning',
        onClick: openEditSection,
      },
    ],
    header: {
      enable: true,
      enableDownload: true,
      downloadbleColumns: xlsColumns,
      addCreateButton: roleService.checkActionPermission(PAGE_NAME, 'CREATE'),
      addCreateButton: true,
      onCreateButtonClick: handleOpen,
      text: 'Renewal',
      enableGlobalSearch: true,
      searchText: 'Search by code, name, type, contact',
      onSelectionChange: handleSelectedRows,
      selectionMenus: [
        { icon: '', text: 'Renew', onClick: handleRenew ,disabled: disableRenew}
      ],
      selectionMenuButtonText: 'Action',
    },
  };

  return (
    <>
      <FettleDataGrid
        $dataSource={dataSource$}
        config={configuration}
        columnsDefination={columnsDefinations}
        onEdit={openEditSection}
      />
    </>
  );
};

export default RenewalListComponent;
